unit Nalogi;

interface

uses
  System.SysUtils, System.Variants, System.Classes, Web, System.Dateutils,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.StdCtrls, TerminalForm,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, Data.DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Controls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.Forms,
  XData.Web.Connection, Vcl.Grids, WEBLib.Buttons, WEBLib.WebCtrls;

type
  TfrmNalogi = class(TTerminalForm)
    tmrFind: TTimer;
    wdsNalogi: TXDataWebDataSet;
    dsNalogi: TDataSource;
    panHeader: TPanel;
    edtFilter: TEdit;
    panFooter: TPanel;
    btnSelect: TButton;
    gridView: TDBGrid;
    btnDelNote: TButton;
    lblIsci: TLabel;
    btnMove: TButton;
    wdsNalogiDatumDostave: TDateField;
    wdsNalogiStrankaNaziv: TStringField;
    wdsNalogiId: TIntegerField;
    wdsNalogiLokacijaOpis: TStringField;
    wdsNalogiTipNalogaId: TIntegerField;
    btnFuel: TButton;
    btnRefresh: TButton;
    btnDanes: TButton;
    procedure tmrFindTimer(Sender: TObject);
    procedure edtFilterChange(Sender: TObject);
    procedure tblStrankeDblClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure wdsNalogiAfterOpen(DataSet: TDataSet);
    procedure WebFormShow(Sender: TObject);
    procedure btnDelNoteClick(Sender: TObject);
    procedure wdsNalogiAfterScroll(DataSet: TDataSet);
    procedure gridViewGetCellClass(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string; var AClassName: string);
    procedure btnMoveClick(Sender: TObject);
    procedure btnFuelClick(Sender: TObject);
    procedure btnDanesClick(Sender: TObject);
  private
    { Private declarations }
    datToday: string;
    datTomorrow: string;
    procedure VrniNaloge(Flt, Srt: string);
    function VrniPozicijo(LatLon: double; PremikM: integer): double;
    procedure ToggleButtons;
    procedure ApplyTheme(Dark: boolean); reintroduce;
  public
    { Public declarations }
    procedure ResetRefreshTimer;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses Client.Core, Main, Client.Settings, Client.Constants;

procedure TfrmNalogi.btnDanesClick(Sender: TObject);
begin
  if btnDanes.Tag = 0 then
    btnDanes.Tag := 1
  else
    btnDanes.Tag := 0;

  if btnDanes.Tag=1 then begin
    btnDanes.ElementClassName := 'btn btn-xxl btn-warning';
  end else begin
    btnDanes.ElementClassName := 'btn btn-xxl btn-primary';
  end;

  tmrFindTimer(Sender);
end;

procedure TfrmNalogi.btnDelNoteClick(Sender: TObject);
begin
  edtFilter.Clear;
  edtFilter.SetFocus;
  tmrFindTimer(Sender)
end;

procedure TfrmNalogi.btnFuelClick(Sender: TObject);
begin
  frmMain.ShowKilometri(kmGorivo);
end;

procedure TfrmNalogi.btnMoveClick(Sender: TObject);
begin
  // izberi vozilo, kamor se prenese nalog
  modMain.NastaviNalog(wdsNalogi.FieldByName('Id').AsInteger);
  modMain.NastaviTipNalog(wdsNalogi.FieldByName('TipNaloga.Id').AsInteger);

  frmMain.ShowMenjava;
end;

procedure TfrmNalogi.ToggleButtons;
begin
  btnSelect.Enabled := wdsNalogi.RecordCount > 0;
  btnMove.Enabled := btnSelect.Enabled;
end;

procedure TfrmNalogi.edtFilterChange(Sender: TObject);
begin
  tmrFind.Enabled := false;
  tmrFind.Enabled := true;
end;

procedure TfrmNalogi.tblStrankeDblClick(Sender: TObject);
begin
  if wdsNalogi.RecordCount<1 then
    exit;

  modMain.NastaviNalog(wdsNalogi.FieldByName('Id').AsInteger);

  frmMain.ShowNalog;
end;

procedure TfrmNalogi.tmrFindTimer(Sender: TObject);
var Flt, Srt: string;
    F: TStringList;

  function Filter(FL: TStringList): string;
  var I: integer;
  begin
    Result := '';
    for I := 0 to FL.Count-1 do begin
      if I > 0 then
        Result := Result + ' and ';

      Result := Result + FL[I];
    end;
  end;

begin
  tmrFind.Enabled := false;

  Flt := '';
  Srt := 'DatumDostave,stranka/naziv';

  F := TStringList.Create;
  F.Add('(status/id eq 1 or status/id eq 2)');

  if btnDanes.Tag=1 then begin
    F.Add('datumdostave le %27'+datToday+'%27');
  end;

  if edtFilter.Text<>'' then begin
    F.Add('(contains(stranka/naziv,%27'+edtFilter.Text+'%27) or contains(lokacijaopis,%27'+edtFilter.Text+'%27) or contains(opombe,%27'+edtFilter.Text+'%27))');
    Srt := 'DatumDostave,stranka/naziv';
  end;

  F.Add('vozilo/id eq '+modMain.VoziloId.ToString);

  Flt := Filter(F);
  F.Free;

  modMain.Log(Flt);

  VrniNaloge(Flt, Srt);
  ToggleButtons;
  ResetRefreshTimer;
end;

// Klic pride iz frmMain
procedure TfrmNalogi.gridViewGetCellClass(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string; var AClassName: string);
//var
//  datRow: string;
begin

//  datRow   := gridView.Cells[2,ARow];  // npr. 2024-04-16

//  if datRow = datToday then begin
//    AClassName := 'done';
//    modMain.Log(datRow+' = '+datToday);
//  end;
end;

procedure TfrmNalogi.ResetRefreshTimer;
begin
end;

procedure TfrmNalogi.VrniNaloge(Flt, Srt: string);
var Qry: string;
begin
//  if Flt<>'' then
//    Flt := Flt + ' and ';

//  Flt := Flt + 'vozilo/id eq '+modMain.VoziloId.ToString;

  Qry := '$filter='+Flt+'&$orderby='+Srt+'&$expand=stranka,tipnaloga';

//  modMain.Log('Qry='+Qry);
  Console.Log('Qry='+Qry);

  wdsNalogi.Close;
  wdsNalogi.QueryString := Qry;
  wdsNalogi.Load;
end;

function TfrmNalogi.VrniPozicijo(LatLon: double; PremikM: integer): double;
begin
end;

procedure TfrmNalogi.wdsNalogiAfterOpen(DataSet: TDataSet);
begin
  ToggleButtons;

  // Nastavi naslednjo lokacijo
  if (wdsNalogi.RecordCount >= modMain.NalogLastRow) and (modMain.NalogLastRow > 0) then begin
//    Console.log('scroll trigger');
    wdsNalogi.RecNo := modMain.NalogLastRow;
  end;

  ResetRefreshTimer;
end;

procedure TfrmNalogi.wdsNalogiAfterScroll(DataSet: TDataSet);
begin
//  Console.log('scroll trigger');
  modMain.NalogLastRow := wdsNalogi.RecNo; // FieldByName('Id').AsInteger;
  modMain.NastaviNalog(wdsNalogi.FieldByName('Id').AsInteger);
  modMain.NastaviTipNalog(wdsNalogi.FieldByName('TipNaloga.Id').AsInteger);
end;

procedure TfrmNalogi.WebFormCreate(Sender: TObject);
begin
  datToday := DateToStr(Today, Fmt);
  datTomorrow := DateToStr(Today+1, Fmt);

  tmrFind.Enabled := true;
end;

procedure TfrmNalogi.WebFormShow(Sender: TObject);
begin
  inherited;

  ApplyTheme(AppSettings.DarkMode);
end;

procedure TfrmNalogi.ApplyTheme(Dark: boolean);
begin
  inherited;
end;

procedure TfrmNalogi.LoadDFMValues;
begin
  inherited LoadDFMValues;

  panHeader := TPanel.Create(Self);
  lblIsci := TLabel.Create(Self);
  edtFilter := TEdit.Create(Self);
  btnDelNote := TButton.Create(Self);
  btnRefresh := TButton.Create(Self);
  btnDanes := TButton.Create(Self);
  panFooter := TPanel.Create(Self);
  btnSelect := TButton.Create(Self);
  btnMove := TButton.Create(Self);
  btnFuel := TButton.Create(Self);
  gridView := TDBGrid.Create(Self);
  tmrFind := TTimer.Create(Self);
  wdsNalogi := TXDataWebDataSet.Create(Self);
  wdsNalogiId := TIntegerField.Create(Self);
  wdsNalogiDatumDostave := TDateField.Create(Self);
  wdsNalogiStrankaNaziv := TStringField.Create(Self);
  wdsNalogiLokacijaOpis := TStringField.Create(Self);
  wdsNalogiTipNalogaId := TIntegerField.Create(Self);
  dsNalogi := TDataSource.Create(Self);

  panHeader.BeforeLoadDFMValues;
  lblIsci.BeforeLoadDFMValues;
  edtFilter.BeforeLoadDFMValues;
  btnDelNote.BeforeLoadDFMValues;
  btnRefresh.BeforeLoadDFMValues;
  btnDanes.BeforeLoadDFMValues;
  panFooter.BeforeLoadDFMValues;
  btnSelect.BeforeLoadDFMValues;
  btnMove.BeforeLoadDFMValues;
  btnFuel.BeforeLoadDFMValues;
  gridView.BeforeLoadDFMValues;
  tmrFind.BeforeLoadDFMValues;
  wdsNalogi.BeforeLoadDFMValues;
  wdsNalogiId.BeforeLoadDFMValues;
  wdsNalogiDatumDostave.BeforeLoadDFMValues;
  wdsNalogiStrankaNaziv.BeforeLoadDFMValues;
  wdsNalogiLokacijaOpis.BeforeLoadDFMValues;
  wdsNalogiTipNalogaId.BeforeLoadDFMValues;
  dsNalogi.BeforeLoadDFMValues;
  try
    Name := 'frmNalogi';
    Left := 0;
    Top := 0;
    Align := alClient;
    Color := clBtnFace;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    panHeader.SetParentComponent(Self);
    panHeader.Name := 'panHeader';
    panHeader.Left := 0;
    panHeader.Top := 0;
    panHeader.Width := 831;
    panHeader.Height := 91;
    panHeader.Align := alTop;
    panHeader.BorderStyle := bsNone;
    panHeader.ChildOrder := 3;
    panHeader.Color := clWhite;
    panHeader.ElementBodyClassName := 'text';
    panHeader.Padding.Left := 5;
    panHeader.Padding.Top := 5;
    panHeader.Padding.Right := 5;
    panHeader.Padding.Bottom := 5;
    panHeader.TabOrder := 1;
    lblIsci.SetParentComponent(panHeader);
    lblIsci.Name := 'lblIsci';
    lblIsci.Left := 5;
    lblIsci.Top := 5;
    lblIsci.Width := 60;
    lblIsci.Height := 81;
    lblIsci.Align := alLeft;
    lblIsci.AutoSize := False;
    lblIsci.Caption := 'I'#353#269'i';
    lblIsci.ElementClassName := 'fs-1';
    lblIsci.ElementFont := efCSS;
    lblIsci.Font.Charset := DEFAULT_CHARSET;
    lblIsci.Font.Color := clWindowText;
    lblIsci.Font.Height := -21;
    lblIsci.Font.Name := 'Tahoma';
    lblIsci.Font.Style := [];
    lblIsci.HeightPercent := 100.000000000000000000;
    lblIsci.Layout := tlCenter;
    lblIsci.ParentFont := False;
    lblIsci.WidthPercent := 100.000000000000000000;
    edtFilter.SetParentComponent(panHeader);
    edtFilter.Name := 'edtFilter';
    edtFilter.AlignWithMargins := True;
    edtFilter.Left := 68;
    edtFilter.Top := 10;
    edtFilter.Width := 476;
    edtFilter.Height := 71;
    edtFilter.Margins.Top := 5;
    edtFilter.Margins.Bottom := 5;
    edtFilter.Align := alClient;
    edtFilter.CharCase := wecUpperCase;
    edtFilter.ChildOrder := 3;
    edtFilter.ElementClassName := 'fs-1';
    edtFilter.ElementFont := efCSS;
    edtFilter.HeightPercent := 100.000000000000000000;
    edtFilter.RequiredText := 'I'#353#269'i...';
    edtFilter.WidthPercent := 100.000000000000000000;
    SetEvent(edtFilter, Self, 'OnChange', 'edtFilterChange');
    btnDelNote.SetParentComponent(panHeader);
    btnDelNote.Name := 'btnDelNote';
    btnDelNote.AlignWithMargins := True;
    btnDelNote.Left := 550;
    btnDelNote.Top := 8;
    btnDelNote.Width := 80;
    btnDelNote.Height := 75;
    btnDelNote.Margins.Right := 10;
    btnDelNote.Align := alRight;
    btnDelNote.Caption := '<i class="material-icons">backspace</i>';
    btnDelNote.ChildOrder := 2;
    btnDelNote.ElementClassName := 'btn btn-xxl btn-primary';
    btnDelNote.ElementFont := efCSS;
    btnDelNote.HeightPercent := 100.000000000000000000;
    btnDelNote.WidthPercent := 100.000000000000000000;
    SetEvent(btnDelNote, Self, 'OnClick', 'btnDelNoteClick');
    btnRefresh.SetParentComponent(panHeader);
    btnRefresh.Name := 'btnRefresh';
    btnRefresh.AlignWithMargins := True;
    btnRefresh.Left := 736;
    btnRefresh.Top := 8;
    btnRefresh.Width := 80;
    btnRefresh.Height := 75;
    btnRefresh.Margins.Right := 10;
    btnRefresh.Align := alRight;
    btnRefresh.Caption := '<i class="material-icons">refresh</i>';
    btnRefresh.ChildOrder := 2;
    btnRefresh.ElementClassName := 'btn btn-xxl btn-primary';
    btnRefresh.ElementFont := efCSS;
    btnRefresh.HeightPercent := 100.000000000000000000;
    btnRefresh.WidthPercent := 100.000000000000000000;
    SetEvent(btnRefresh, Self, 'OnClick', 'tmrFindTimer');
    btnDanes.SetParentComponent(panHeader);
    btnDanes.Name := 'btnDanes';
    btnDanes.Tag := 1;
    btnDanes.AlignWithMargins := True;
    btnDanes.Left := 643;
    btnDanes.Top := 8;
    btnDanes.Width := 80;
    btnDanes.Height := 75;
    btnDanes.Margins.Right := 10;
    btnDanes.Align := alRight;
    btnDanes.Caption := '<i class="material-icons">today</i>';
    btnDanes.ChildOrder := 2;
    btnDanes.ElementClassName := 'btn btn-xxl btn-warning';
    btnDanes.ElementFont := efCSS;
    btnDanes.HeightPercent := 100.000000000000000000;
    btnDanes.WidthPercent := 100.000000000000000000;
    SetEvent(btnDanes, Self, 'OnClick', 'btnDanesClick');
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 706;
    panFooter.Width := 831;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.Padding.Top := 5;
    panFooter.TabOrder := 2;
    btnSelect.SetParentComponent(panFooter);
    btnSelect.Name := 'btnSelect';
    btnSelect.AlignWithMargins := True;
    btnSelect.Left := 656;
    btnSelect.Top := 10;
    btnSelect.Width := 165;
    btnSelect.Height := 107;
    btnSelect.Margins.Top := 5;
    btnSelect.Margins.Right := 10;
    btnSelect.Align := alRight;
    btnSelect.Caption := '<i class="material-icons" style="font-size:40px;">done</i>';
    btnSelect.ChildOrder := 1;
    btnSelect.ElementClassName := 'btn btn-xxl btn-success';
    btnSelect.ElementFont := efCSS;
    btnSelect.Enabled := False;
    btnSelect.HeightPercent := 100.000000000000000000;
    btnSelect.WidthPercent := 100.000000000000000000;
    SetEvent(btnSelect, Self, 'OnClick', 'tblStrankeDblClick');
    btnMove.SetParentComponent(panFooter);
    btnMove.Name := 'btnMove';
    btnMove.AlignWithMargins := True;
    btnMove.Left := 10;
    btnMove.Top := 8;
    btnMove.Width := 111;
    btnMove.Height := 109;
    btnMove.Margins.Left := 10;
    btnMove.Margins.Right := 10;
    btnMove.Align := alLeft;
    btnMove.Caption := '<i class="material-icons" style="font-size:40px;">move_up</i>';
    btnMove.ChildOrder := 1;
    btnMove.ElementClassName := 'btn btn-primary';
    btnMove.ElementFont := efCSS;
    btnMove.HeightPercent := 100.000000000000000000;
    btnMove.WidthPercent := 100.000000000000000000;
    SetEvent(btnMove, Self, 'OnClick', 'btnMoveClick');
    btnFuel.SetParentComponent(panFooter);
    btnFuel.Name := 'btnFuel';
    btnFuel.AlignWithMargins := True;
    btnFuel.Left := 141;
    btnFuel.Top := 8;
    btnFuel.Width := 111;
    btnFuel.Height := 109;
    btnFuel.Margins.Left := 10;
    btnFuel.Margins.Right := 10;
    btnFuel.Align := alLeft;
    btnFuel.Caption := '<i class="material-icons" style="font-size:40px;">local_gas_station</i>';
    btnFuel.ChildOrder := 1;
    btnFuel.ElementClassName := 'btn btn-warning';
    btnFuel.ElementFont := efCSS;
    btnFuel.HeightPercent := 100.000000000000000000;
    btnFuel.WidthPercent := 100.000000000000000000;
    SetEvent(btnFuel, Self, 'OnClick', 'btnFuelClick');
    gridView.SetParentComponent(Self);
    gridView.Name := 'gridView';
    gridView.Left := 0;
    gridView.Top := 91;
    gridView.Width := 831;
    gridView.Height := 615;
    gridView.Align := alClient;
    gridView.BorderStyle := bsNone;
    gridView.Color := clWhite;
    gridView.Columns.Clear;
    with gridView.Columns.Add do
    begin
      DataField := 'DatumDostave';
      Width := 135;
    end;
    with gridView.Columns.Add do
    begin
      ElementClassName := 'text-wrap';
      DataField := 'Stranka.Naziv';
      Title := 'Stranka';
      Width := 230;
    end;
    with gridView.Columns.Add do
    begin
      ElementClassName := 'text-wrap';
      DataField := 'LokacijaOpis';
      Title := 'Lokacija';
      Width := 230;
    end;
    gridView.DefaultRowHeight := 80;
    gridView.DataSource := dsNalogi;
    gridView.FixedFont.Charset := DEFAULT_CHARSET;
    gridView.FixedFont.Color := clWindowText;
    gridView.FixedFont.Height := -12;
    gridView.FixedFont.Name := 'Segoe UI';
    gridView.FixedFont.Style := [];
    gridView.FixedCols := 0;
    gridView.FixedRows := 0;
    gridView.Font.Charset := DEFAULT_CHARSET;
    gridView.Font.Color := clWindowText;
    gridView.Font.Height := -28;
    gridView.Font.Name := 'Tahoma';
    gridView.Font.Style := [];
    gridView.Options := [goHorzLine,goRowSelect];
    gridView.ParentFont := False;
    gridView.TabOrder := 0;
    gridView.HeightPercent := 100.000000000000000000;
    gridView.ShowIndicator := False;
    gridView.WidthPercent := 100.000000000000000000;
    SetEvent(gridView, Self, 'OnDblClick', 'tblStrankeDblClick');
    SetEvent(gridView, Self, 'OnGetCellClass', 'gridViewGetCellClass');
    gridView.ColWidths[0] := 135;
    gridView.ColWidths[1] := 230;
    gridView.ColWidths[2] := 230;
    tmrFind.SetParentComponent(Self);
    tmrFind.Name := 'tmrFind';
    tmrFind.Enabled := False;
    tmrFind.Interval := 500;
    SetEvent(tmrFind, Self, 'OnTimer', 'tmrFindTimer');
    tmrFind.Left := 100;
    tmrFind.Top := 328;
    wdsNalogi.SetParentComponent(Self);
    wdsNalogi.Name := 'wdsNalogi';
    wdsNalogi.AfterOpen := wdsNalogiAfterOpen;
    wdsNalogi.AfterScroll := wdsNalogiAfterScroll;
    wdsNalogi.EntitySetName := 'PrevozniNalog';
    wdsNalogi.Connection := modMain.connServer;
    wdsNalogi.SubpropsDepth := 1;
    wdsNalogi.QueryString := 'filter=Aktivna%20ne%200&$orderby=Stranka/Naziv,PrevzemnoMesto&$expand=stranka';
    wdsNalogi.Left := 104;
    wdsNalogi.Top := 216;
    wdsNalogiId.SetParentComponent(wdsNalogi);
    wdsNalogiId.Name := 'wdsNalogiId';
    wdsNalogiId.FieldName := 'Id';
    wdsNalogiDatumDostave.SetParentComponent(wdsNalogi);
    wdsNalogiDatumDostave.Name := 'wdsNalogiDatumDostave';
    wdsNalogiDatumDostave.FieldName := 'DatumDostave';
    wdsNalogiDatumDostave.DisplayFormat := 'dd.mm.yy';
    wdsNalogiStrankaNaziv.SetParentComponent(wdsNalogi);
    wdsNalogiStrankaNaziv.Name := 'wdsNalogiStrankaNaziv';
    wdsNalogiStrankaNaziv.FieldName := 'Stranka.Naziv';
    wdsNalogiStrankaNaziv.Size := 50;
    wdsNalogiLokacijaOpis.SetParentComponent(wdsNalogi);
    wdsNalogiLokacijaOpis.Name := 'wdsNalogiLokacijaOpis';
    wdsNalogiLokacijaOpis.FieldName := 'LokacijaOpis';
    wdsNalogiLokacijaOpis.Size := 50;
    wdsNalogiTipNalogaId.SetParentComponent(wdsNalogi);
    wdsNalogiTipNalogaId.Name := 'wdsNalogiTipNalogaId';
    wdsNalogiTipNalogaId.FieldName := 'TipNaloga.Id';
    dsNalogi.SetParentComponent(Self);
    dsNalogi.Name := 'dsNalogi';
    dsNalogi.DataSet := wdsNalogi;
    dsNalogi.Left := 104;
    dsNalogi.Top := 272;
  finally
    panHeader.AfterLoadDFMValues;
    lblIsci.AfterLoadDFMValues;
    edtFilter.AfterLoadDFMValues;
    btnDelNote.AfterLoadDFMValues;
    btnRefresh.AfterLoadDFMValues;
    btnDanes.AfterLoadDFMValues;
    panFooter.AfterLoadDFMValues;
    btnSelect.AfterLoadDFMValues;
    btnMove.AfterLoadDFMValues;
    btnFuel.AfterLoadDFMValues;
    gridView.AfterLoadDFMValues;
    tmrFind.AfterLoadDFMValues;
    wdsNalogi.AfterLoadDFMValues;
    wdsNalogiId.AfterLoadDFMValues;
    wdsNalogiDatumDostave.AfterLoadDFMValues;
    wdsNalogiStrankaNaziv.AfterLoadDFMValues;
    wdsNalogiLokacijaOpis.AfterLoadDFMValues;
    wdsNalogiTipNalogaId.AfterLoadDFMValues;
    dsNalogi.AfterLoadDFMValues;
  end;
end;

end.
