unit KontejnerPredaja;

interface

uses
  System.SysUtils, System.Variants, System.Classes, Web, Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  Data.DB, WEBLib.DB, XData.Web.JsonDataset, TerminalForm, XData.Web.Dataset, WEBLib.Controls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.Forms, XData.Web.Connection,
  Vcl.Grids;

type
  TfrmPredajaKontejner = class(TTerminalForm)
    tmrFind: TTimer;
    wdsMenjava: TXDataWebDataSet;
    dsMenjava: TDataSource;
    panFooter: TPanel;
    btnSelect: TButton;
    gridView: TDBGrid;
    btnCancel: TButton;
    lblTitle: TLabel;
    procedure tmrFindTimer(Sender: TObject);
    procedure tblStrankeDblClick(Sender: TObject);
    procedure wdsMenjavaAfterOpen(DataSet: TDataSet);
    procedure btnCancelClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
  private
    { Private declarations }
  public
    { Public declarations }
    NalogId: integer;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses Client.Core, Main, Client.Settings;

procedure TfrmPredajaKontejner.btnCancelClick(Sender: TObject);
begin
  frmMain.ShowNalog;
end;

procedure TfrmPredajaKontejner.tblStrankeDblClick(Sender: TObject);
begin
//  modMain.PredajCnt(NalogId, );
//  frmMain.ShowFrakcija;
//  frmMain.ShowVozila;
  frmMain.ShowNalog;
end;

procedure TfrmPredajaKontejner.tmrFindTimer(Sender: TObject);
begin
  tmrFind.Enabled := false;

  wdsMenjava.Close;
  wdsMenjava.QueryString := '$filter=Nalog/Id eq '+NalogId.ToString+' and status/id lt 3&$expand=Kontejner,Frakcija&$orderby=Id';
//  modMain.Log('Qry='+wdsMenjava.QueryString);
  wdsMenjava.Load;

//  gridView.ColWidths[0] := gridView.Width mod 2;
//  gridView.ColWidths[1] := gridView.Width mod 2;
end;

procedure TfrmPredajaKontejner.wdsMenjavaAfterOpen(DataSet: TDataSet);
begin
  lblTitle.Caption := 'Izberi kaj predaš na '+modMain.VoziloMenjavaId.ToString; //  frmMain.lblVozilo.Caption;
  btnSelect.Enabled := DataSet.RecordCount > 0;
//  ShowMEssage(DataSet.RecordCount.ToString);
end;

procedure TfrmPredajaKontejner.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Action := caFree;
end;

procedure TfrmPredajaKontejner.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lblTitle := TLabel.Create(Self);
  panFooter := TPanel.Create(Self);
  btnSelect := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  gridView := TDBGrid.Create(Self);
  tmrFind := TTimer.Create(Self);
  wdsMenjava := TXDataWebDataSet.Create(Self);
  dsMenjava := TDataSource.Create(Self);

  lblTitle.BeforeLoadDFMValues;
  panFooter.BeforeLoadDFMValues;
  btnSelect.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  gridView.BeforeLoadDFMValues;
  tmrFind.BeforeLoadDFMValues;
  wdsMenjava.BeforeLoadDFMValues;
  dsMenjava.BeforeLoadDFMValues;
  try
    Name := 'frmPredajaKontejner';
    Left := 0;
    Top := 0;
    Align := alClient;
    Caption := 'modMain.connServer';
    Color := 14803455;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    SetEvent(Self, 'OnClose', 'WebFormClose');
    lblTitle.SetParentComponent(Self);
    lblTitle.Name := 'lblTitle';
    lblTitle.Left := 0;
    lblTitle.Top := 0;
    lblTitle.Width := 752;
    lblTitle.Height := 13;
    lblTitle.Align := alTop;
    lblTitle.Alignment := taCenter;
    lblTitle.Caption := 'Izberi kontejnerje za predajo';
    lblTitle.Color := 14803455;
    lblTitle.ElementClassName := 'display-3 text-danger';
    lblTitle.ElementFont := efCSS;
    lblTitle.HeightStyle := ssAuto;
    lblTitle.HeightPercent := 100.000000000000000000;
    lblTitle.WidthPercent := 100.000000000000000000;
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 764;
    panFooter.Width := 752;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.TabOrder := 0;
    btnSelect.SetParentComponent(panFooter);
    btnSelect.Name := 'btnSelect';
    btnSelect.AlignWithMargins := True;
    btnSelect.Left := 577;
    btnSelect.Top := 5;
    btnSelect.Width := 165;
    btnSelect.Height := 112;
    btnSelect.Margins.Top := 5;
    btnSelect.Margins.Right := 10;
    btnSelect.Align := alRight;
    btnSelect.Caption := '<i class="material-icons" style="font-size:40px;">done</i>';
    btnSelect.ChildOrder := 1;
    btnSelect.ElementClassName := 'btn btn-success';
    btnSelect.ElementFont := efCSS;
    btnSelect.Enabled := False;
    btnSelect.HeightPercent := 100.000000000000000000;
    btnSelect.WidthPercent := 100.000000000000000000;
    SetEvent(btnSelect, Self, 'OnClick', 'tblStrankeDblClick');
    btnCancel.SetParentComponent(panFooter);
    btnCancel.Name := 'btnCancel';
    btnCancel.AlignWithMargins := True;
    btnCancel.Left := 10;
    btnCancel.Top := 3;
    btnCancel.Width := 111;
    btnCancel.Height := 114;
    btnCancel.Margins.Left := 10;
    btnCancel.Margins.Right := 10;
    btnCancel.Align := alLeft;
    btnCancel.Caption := '<i class="material-icons" style="font-size:40px;">close</i>';
    btnCancel.ChildOrder := 1;
    btnCancel.ElementClassName := 'btn btn-danger';
    btnCancel.ElementFont := efCSS;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
    gridView.SetParentComponent(Self);
    gridView.Name := 'gridView';
    gridView.Left := 0;
    gridView.Top := 13;
    gridView.Width := 752;
    gridView.Height := 751;
    gridView.Align := alClient;
    gridView.Color := clWhite;
    gridView.Columns.Clear;
    with gridView.Columns.Add do
    begin
      DataField := 'Izbrano';
      DataType := cdCheck;
    end;
    with gridView.Columns.Add do
    begin
      DataField := 'Kontejner.Opis';
      Title := 'Opis';
      Width := 320;
    end;
    with gridView.Columns.Add do
    begin
      DataField := 'Frakcija.Opis';
      Title := 'Frakcija';
      Width := 320;
    end;
    gridView.DefaultRowHeight := 60;
    gridView.DataSource := dsMenjava;
    gridView.ElementFont := efCSS;
    gridView.ElementClassName := 'display-1';
    gridView.FixedFont.Charset := DEFAULT_CHARSET;
    gridView.FixedFont.Color := clWindowText;
    gridView.FixedFont.Height := -12;
    gridView.FixedFont.Name := 'Segoe UI';
    gridView.FixedFont.Style := [];
    gridView.FixedCols := 0;
    gridView.FixedRows := 0;
    gridView.Font.Charset := DEFAULT_CHARSET;
    gridView.Font.Color := clWindowText;
    gridView.Font.Height := -83;
    gridView.Font.Name := 'Tahoma';
    gridView.Font.Style := [];
    gridView.Options := [goRowSelect];
    gridView.ParentFont := False;
    gridView.TabOrder := 1;
    gridView.HeightPercent := 100.000000000000000000;
    gridView.ShowIndicator := False;
    gridView.WidthPercent := 100.000000000000000000;
    SetEvent(gridView, Self, 'OnDblClick', 'tblStrankeDblClick');
    gridView.ColWidths[0] := 64;
    gridView.ColWidths[1] := 320;
    gridView.ColWidths[2] := 320;
    tmrFind.SetParentComponent(Self);
    tmrFind.Name := 'tmrFind';
    tmrFind.Interval := 500;
    SetEvent(tmrFind, Self, 'OnTimer', 'tmrFindTimer');
    tmrFind.Left := 108;
    tmrFind.Top := 104;
    wdsMenjava.SetParentComponent(Self);
    wdsMenjava.Name := 'wdsMenjava';
    wdsMenjava.AfterOpen := wdsMenjavaAfterOpen;
    wdsMenjava.EntitySetName := 'PrevozniNalogPodrobno';
    wdsMenjava.Connection := modMain.connServer;
    wdsMenjava.SubpropsDepth := 1;
    wdsMenjava.Left := 104;
    wdsMenjava.Top := 256;
    dsMenjava.SetParentComponent(Self);
    dsMenjava.Name := 'dsMenjava';
    dsMenjava.DataSet := wdsMenjava;
    dsMenjava.Left := 104;
    dsMenjava.Top := 312;
  finally
    lblTitle.AfterLoadDFMValues;
    panFooter.AfterLoadDFMValues;
    btnSelect.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    gridView.AfterLoadDFMValues;
    tmrFind.AfterLoadDFMValues;
    wdsMenjava.AfterLoadDFMValues;
    dsMenjava.AfterLoadDFMValues;
  end;
end;

end.

