unit Vozilo;

interface

uses
  System.SysUtils, System.Variants, System.Classes, Web,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.StdCtrls, TerminalForm,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, Data.DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Controls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.Forms,
  XData.Web.Connection, Vcl.Grids;

type
  TfrmVozilo = class(TTerminalForm)
    tmrFind: TTimer;
    wdsVozilo: TXDataWebDataSet;
    dsVozilo: TDataSource;
    panFooter: TPanel;
    btnSelect: TButton;
    gridView: TDBGrid;
    btnMenjava: TButton;
    btnCancel: TButton;
    btnDan: TButton;
    procedure edtFilterChange(Sender: TObject);
    procedure tblStrankeDblClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure wdsVoziloAfterOpen(DataSet: TDataSet);
    procedure btnMenjavaClick(Sender: TObject);
    procedure btnCancelClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure btnDanClick(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    procedure ApplyTheme(Dark: boolean); reintroduce;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}
{$I Defines.inc}

uses Client.Core, Main, Client.Settings, Client.Constants;

procedure TfrmVozilo.ApplyTheme(Dark: boolean);
begin
  inherited;
  //
end;

procedure TfrmVozilo.btnCancelClick(Sender: TObject);
begin
  frmMain.ShowLogin;
end;

procedure TfrmVozilo.btnDanClick(Sender: TObject);
begin
  frmMain.ShowKoledar(knPrivzeto, '');
end;

procedure TfrmVozilo.btnMenjavaClick(Sender: TObject);
begin
  modMain.NastaviVozilo(wdsVozilo.FieldByName('Id').AsInteger, false);
  frmMain.ShowMenjava;
end;

procedure TfrmVozilo.edtFilterChange(Sender: TObject);
begin
  tmrFind.Enabled := false;
  tmrFind.Enabled := true;
end;

procedure TfrmVozilo.tblStrankeDblClick(Sender: TObject);
begin
  modMain.NastaviVozilo(wdsVozilo.FieldByName('Id').AsInteger, true);
end;

procedure TfrmVozilo.wdsVoziloAfterOpen(DataSet: TDataSet);
var VoziloId: integer;
begin
  btnSelect.Enabled := DataSet.RecordCount > 0;
  btnMenjava.Enabled := btnSelect.Enabled;

  tmrFind.Enabled := true;
  // TODO: Pozor - blokira Android, če mora grid narediti scroll zaradi locate ukaza! Trenutno ni rešitve, niti s timerjem po odpiranju.
  VoziloId := StrToIntDef(modMain.GetVar('VoziloId'),0);
  if (wdsVozilo.RecordCount<11) and (VoziloId>0) then try //TODO: Če je več vrstic kot višina zaslona, zablokira...
    wdsVozilo.Locate('Id', VoziloId, []);
  except
    modMain.log('Napaka pri lociranju vozila');
  end;

  wdsVozilo.Locate('Id', VoziloId, []);
end;

procedure TfrmVozilo.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  inherited;
//  Action := caFree;
end;

procedure TfrmVozilo.WebFormCreate(Sender: TObject);
begin
  modMain.VoziloMenjavaId := 0;  // Reset menjave
  modMain.VoziloId := 0;
  modMain.VoziloStanjeKm := 0;

  wdsVozilo.Close;
  wdsVozilo.QueryString := '$filter=Aktivno eq 1&$orderby=RegistrskaSt';
  wdsVozilo.Load;
end;

procedure TfrmVozilo.WebFormShow(Sender: TObject);
begin
  inherited;
  gridView.ColWidths[0] := gridView.Width - 40;

  ApplyTheme(AppSettings.DarkMode);
end;

procedure TfrmVozilo.LoadDFMValues;
begin
  inherited LoadDFMValues;

  panFooter := TPanel.Create(Self);
  btnSelect := TButton.Create(Self);
  btnMenjava := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  btnDan := TButton.Create(Self);
  gridView := TDBGrid.Create(Self);
  tmrFind := TTimer.Create(Self);
  wdsVozilo := TXDataWebDataSet.Create(Self);
  dsVozilo := TDataSource.Create(Self);

  panFooter.BeforeLoadDFMValues;
  btnSelect.BeforeLoadDFMValues;
  btnMenjava.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  btnDan.BeforeLoadDFMValues;
  gridView.BeforeLoadDFMValues;
  tmrFind.BeforeLoadDFMValues;
  wdsVozilo.BeforeLoadDFMValues;
  dsVozilo.BeforeLoadDFMValues;
  try
    Name := 'frmVozilo';
    Left := 0;
    Top := 0;
    Align := alClient;
    Color := clBtnFace;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 764;
    panFooter.Width := 752;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderColor := clNone;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.Padding.Top := 5;
    panFooter.TabOrder := 0;
    btnSelect.SetParentComponent(panFooter);
    btnSelect.Name := 'btnSelect';
    btnSelect.AlignWithMargins := True;
    btnSelect.Left := 577;
    btnSelect.Top := 10;
    btnSelect.Width := 165;
    btnSelect.Height := 107;
    btnSelect.Margins.Top := 5;
    btnSelect.Margins.Right := 10;
    btnSelect.Align := alRight;
    btnSelect.Caption := '<i class="material-icons" style="font-size:40px;">done</i>';
    btnSelect.ChildOrder := 1;
    btnSelect.ElementClassName := 'btn btn-success';
    btnSelect.ElementFont := efCSS;
    btnSelect.Enabled := False;
    btnSelect.HeightPercent := 100.000000000000000000;
    btnSelect.WidthPercent := 100.000000000000000000;
    SetEvent(btnSelect, Self, 'OnClick', 'tblStrankeDblClick');
    btnMenjava.SetParentComponent(panFooter);
    btnMenjava.Name := 'btnMenjava';
    btnMenjava.AlignWithMargins := True;
    btnMenjava.Left := 141;
    btnMenjava.Top := 8;
    btnMenjava.Width := 111;
    btnMenjava.Height := 109;
    btnMenjava.Margins.Left := 10;
    btnMenjava.Margins.Right := 10;
    btnMenjava.Align := alLeft;
    btnMenjava.Caption := '<i class="material-icons" style="font-size:40px;">local_shipping</i>';
    btnMenjava.ChildOrder := 1;
    btnMenjava.ElementClassName := 'btn btn-warning';
    btnMenjava.ElementFont := efCSS;
    btnMenjava.Enabled := False;
    btnMenjava.HeightPercent := 100.000000000000000000;
    btnMenjava.WidthPercent := 100.000000000000000000;
    SetEvent(btnMenjava, Self, 'OnClick', 'btnMenjavaClick');
    btnCancel.SetParentComponent(panFooter);
    btnCancel.Name := 'btnCancel';
    btnCancel.AlignWithMargins := True;
    btnCancel.Left := 10;
    btnCancel.Top := 8;
    btnCancel.Width := 111;
    btnCancel.Height := 109;
    btnCancel.Margins.Left := 10;
    btnCancel.Margins.Right := 10;
    btnCancel.Align := alLeft;
    btnCancel.Caption := '<i class="material-icons" style="font-size:40px;">keyboard_backspace</i>';
    btnCancel.ChildOrder := 1;
    btnCancel.ElementClassName := 'btn btn-danger';
    btnCancel.ElementFont := efCSS;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
    btnDan.SetParentComponent(panFooter);
    btnDan.Name := 'btnDan';
    btnDan.AlignWithMargins := True;
    btnDan.Left := 272;
    btnDan.Top := 8;
    btnDan.Width := 111;
    btnDan.Height := 109;
    btnDan.Margins.Left := 10;
    btnDan.Margins.Right := 10;
    btnDan.Align := alLeft;
    btnDan.Caption := '<i class="material-icons" style="font-size:40px;">calendar_month</i>';
    btnDan.ChildOrder := 1;
    btnDan.ElementClassName := 'btn btn-primary';
    btnDan.ElementFont := efCSS;
    btnDan.HeightPercent := 100.000000000000000000;
    btnDan.Visible := False;
    btnDan.WidthPercent := 100.000000000000000000;
    SetEvent(btnDan, Self, 'OnClick', 'btnDanClick');
    gridView.SetParentComponent(Self);
    gridView.Name := 'gridView';
    gridView.Left := 0;
    gridView.Top := 0;
    gridView.Width := 752;
    gridView.Height := 764;
    gridView.Align := alClient;
    gridView.BorderStyle := bsNone;
    gridView.Color := clWhite;
    gridView.Columns.Clear;
    with gridView.Columns.Add do
    begin
      ElementClassName := 'display-1';
      DataField := 'RegistrskaSt';
      Title := 'Opis';
      Width := 400;
    end;
    gridView.DataSource := dsVozilo;
    gridView.FixedFont.Charset := DEFAULT_CHARSET;
    gridView.FixedFont.Color := clWindowText;
    gridView.FixedFont.Height := -12;
    gridView.FixedFont.Name := 'Segoe UI';
    gridView.FixedFont.Style := [];
    gridView.FixedCols := 0;
    gridView.FixedRows := 0;
    gridView.Font.Charset := DEFAULT_CHARSET;
    gridView.Font.Color := clWindowText;
    gridView.Font.Height := -83;
    gridView.Font.Name := 'Tahoma';
    gridView.Font.Style := [];
    gridView.Options := [goRowSelect];
    gridView.ParentFont := False;
    gridView.TabOrder := 1;
    gridView.HeightPercent := 100.000000000000000000;
    gridView.SelectionColor := 14239232;
    gridView.ShowIndicator := False;
    gridView.WidthPercent := 100.000000000000000000;
    SetEvent(gridView, Self, 'OnDblClick', 'tblStrankeDblClick');
    gridView.ColWidths[0] := 400;
    tmrFind.SetParentComponent(Self);
    tmrFind.Name := 'tmrFind';
    tmrFind.Enabled := False;
    tmrFind.Interval := 2000;
    tmrFind.Left := 108;
    tmrFind.Top := 104;
    wdsVozilo.SetParentComponent(Self);
    wdsVozilo.Name := 'wdsVozilo';
    wdsVozilo.AfterOpen := wdsVoziloAfterOpen;
    wdsVozilo.EntitySetName := 'Vozilo';
    wdsVozilo.Connection := modMain.connServer;
    wdsVozilo.SubpropsDepth := 1;
    wdsVozilo.Left := 104;
    wdsVozilo.Top := 256;
    dsVozilo.SetParentComponent(Self);
    dsVozilo.Name := 'dsVozilo';
    dsVozilo.DataSet := wdsVozilo;
    dsVozilo.Left := 104;
    dsVozilo.Top := 312;
  finally
    panFooter.AfterLoadDFMValues;
    btnSelect.AfterLoadDFMValues;
    btnMenjava.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    btnDan.AfterLoadDFMValues;
    gridView.AfterLoadDFMValues;
    tmrFind.AfterLoadDFMValues;
    wdsVozilo.AfterLoadDFMValues;
    dsVozilo.AfterLoadDFMValues;
  end;
end;

end.
