unit Client.Core;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Modules, XData.Web.Connection, Math,
  Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Client, DateUtils, Client.Constants,
  WEBLib.Dialogs, WEBLib.Forms, Auth.Service, WEBLib.Cookies, WEBLIB.Storage, WEBLib.ExtCtrls;

type
  TOnLineEvent = procedure(IsConnected: boolean) of object;

  TmodMain = class(TDataModule)
    connServer: TXDataWebConnection;
    wdsVozila: TXDataWebDataSet;
    wdsVozniki: TXDataWebDataSet;
    wdsFrakcije: TXDataWebDataSet;
    wdsFrakcijeId: TIntegerField;
    wdsFrakcijeOpis: TStringField;
    wdsFrakcijeAktivno: TIntegerField;
    wdsVoznikiId: TIntegerField;
    wdsVoznikiUporabniskoIme: TStringField;
    wdsVoznikiGeslo: TStringField;
    wdsVoznikiPIN: TStringField;
    wdsVoznikiIme: TStringField;
    wdsVoznikiPriimek: TStringField;
    wdsVoznikiAktiven: TIntegerField;
    wdsPoti: TXDataWebDataSet;
    wdsLokacije: TXDataWebDataSet;
    wdsMenjava: TXDataWebDataSet;
    wdsDnevi: TXDataWebDataSet;
    cliMain: TXDataWebClient;
    geoLoc: TGeoLocation;
    procedure WebDataModuleCreate(Sender: TObject);
    procedure wdsVozilaAfterOpen(DataSet: TDataSet);
    procedure wdsVoznikiAfterOpen(DataSet: TDataSet);
    procedure wdsFrakcijeAfterOpen(DataSet: TDataSet);
    procedure wdsLokacijeAfterOpen(DataSet: TDataSet);
    procedure wdsMenjavaAfterOpen(DataSet: TDataSet);
    procedure wdsDneviAfterOpen(DataSet: TDataSet);
    procedure connServerRequest(Args: TXDataWebConnectionRequest);
    procedure SporociLokacijo(AVoziloId: integer; Lat, Lon: double);
    procedure geoLocGeolocation(Sender: TObject; Lat, Lon, Alt: Double);
    procedure connServerConnect(Sender: TObject);
    procedure ZazeniSledenje;
    procedure UstaviSledenje;
    procedure connServerError(Error: TXDataWebConnectionError);
    procedure geoLocGeolocationError(Sender: TObject; ACode: Integer; AMessage: string);

    procedure OnLineChanged(Sender: TObject; AStatus: TOnlineStatus);
  private
    { Private declarations }
    FIsOnLine: boolean;
    FIsConnected: boolean;
    FOnOnLine: TOnLineEvent;
    FOnConnected: TOnLineEvent;
    function RazdaljaM(lat1, lon1, lat2, lon2: double): double;
  public
    { Public declarations }

    StrankaId: integer;
    Stranka: TObject;

    PotId: integer;
    Pot: TObject;

    LokacijaId: integer;
    Lokacija: TObject;

    VoziloId: integer;
    Vozilo: TObject;
    VoziloKolicina: boolean;
    VoziloVolumen: boolean;
    VoziloTeza: boolean;
    VoziloStanjeKm: integer;
    VoziloRegSt: string;
    VoziloTip: integer;

    VoziloMenjavaId: integer;
    VoziloMenjava: TObject;
    VoziloMenjavaRegSt: string;
    VoziloMenjavaStanjeKm: integer;

    VoznikId: integer;
    VoznikUserName: string;
    VoznikIme: string;
    Voznik: TObject;

    FrakcijaId: integer;
    Frakcija: TObject;

    OdvozId: integer;

    NalogId: integer;
    TipNalogaId: integer;
    NalogPodrobnoId: integer;

    NalogLastRow: integer;

    LokacijaLastRow: integer;
    OdvozLastRow: integer;

    IzbranDan: integer;
    IzbranDatum: TDate;
    IzbranDanIme: string;
    ZgodovinaDatum: TDate;

    GeoLocationEnabled: boolean;
    LokacijaLat: double;
    LokacijaLon: double;
    HasMoved: boolean;
    TargetLat: double;
    TargetLon: double;

    AuthService: TAuthService;

    // Skupno
    procedure NastaviVoznika(Pin: string);
    procedure NastaviVozilo(Id: integer; PrikaziKm: boolean);
    procedure NastaviMenjavo(Id: integer);
    procedure NastaviDatum(ADatum: TDateTime);
    procedure NastaviDatumZgodovina(ADatum: TDateTime);
    procedure NastaviFrakcijo(Id: integer);
    procedure NastaviLokacijo(Id: integer);
    procedure TehtajVozilo(VoziloId: integer; VoznikUserName: string);
    function VrniTipvozila: TVoziloTip;

    procedure NastaviStevecKm(VoziloId, StevecKm: integer; Opombe: string);
    procedure VpisiGorivo(VoziloId: integer; Litri: double; Opombe: string);

    function GetVar(TokenName: string): string;
    procedure SetVar(TokenName, Value: string);

    property OnConnected: TOnLineEvent read FOnConnected write FOnConnected;
    property IsOnline: boolean read FIsOnLine;
    property IsConnected: boolean read FIsConnected; // write FIsConnected;

    function VrniDanVTednu(Datum: TDateTime): integer;
    procedure Log(Txt: string);

    // Norba
    procedure NastaviPot(APotId: integer);

    // Nakladač
    procedure NastaviNalog(ANalogId: integer);
    procedure NastaviTipNalog(TipNalogId: integer);
    procedure PrenesiNalog(NalogId, VoziloId: integer);
    procedure ZakljuciNalog(NalogId: integer);
    procedure SetCoordinate(Lon, Lat: double);

    procedure NastaviVoznikDostava(NalogPodrobnoId, VoznikId: integer);
    procedure NastaviVoznikOdvoz(NalogPodrobnoId, VoznikId: integer);
    procedure PosljiZahtevekTehtanje(NalogPodrobnoId: integer);
    function TehtajCnt(NalogPodrobnoId: integer): boolean;
    procedure VpisiStoritveCnt(NalogPodrobnoId: integer; Pranje: boolean; LocevanjeUr, LocevanjeDelavci, DvigaloUr: integer);
    procedure VpisiTezoCnt(NalogPodrobnoId, TezaKg: integer);
    function RazloziCnt(NalogPodrobnoId: integer): boolean;
    function NaloziCnt(NalogPodrobnoId: integer): boolean;
  protected procedure LoadDFMValues; override; end;

const
  MaxDelta = 500;  // Največja razdalja (v m), da se procesira lokacija. To je zaradi velikih naključnih odstopov.
  MinDelta = 10;  // najmanjši premik (v m), da se procesira lokacija.

var
  modMain: TmodMain;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

uses Client.Settings, Main;

{$R *.dfm}

procedure TmodMain.WebDataModuleCreate(Sender: TObject);
begin
  AppSettings := TSettings.Create;
  Application.OnOnlineChange := OnLineChanged;

  self.GeoLocationEnabled := true; // Privzeto ON ob vsaki prijavi ali ponovnem zagonu

  AuthService := TAuthService.Create(connServer);

  IzbranDan := VrniDanVTednu(Today);

  connServer.URL := AppSettings.ServerURL;
  OnLineChanged(nil, osOnline);
end;

procedure TmodMain.ZazeniSledenje;
begin
  Log('Starting tracking...');
  geoLoc.StartTracking;
end;

procedure TmodMain.OnLineChanged(Sender: TObject; AStatus: TOnlineStatus);
begin
  log('AStatus='+IntToStr(Ord(AStatus)));

  FIsOnLine := AStatus = osOnline;

  if AStatus = osOnline then begin
    if not connServer.Connected then begin
      log('Connecting to server '+connServer.URL);
      connServer.Connected := true;
    end;
  end;

  if Assigned(FOnConnected) then
    FOnConnected(FIsOnline);
end;

procedure TmodMain.connServerConnect(Sender: TObject);
begin
  log('Connected to server');
  FIsOnLine := true;
  FIsConnected := connServer.Connected;

  if Assigned(FOnConnected) then
    FOnConnected(FIsOnline);
end;

procedure TmodMain.connServerError(Error: TXDataWebConnectionError);
begin
  Log(Error.ErrorMessage);
  FIsOnLine := Application.IsOnline;
  FIsConnected := connServer.Connected;
end;

procedure TmodMain.connServerRequest(Args: TXDataWebConnectionRequest);
begin
  if AuthService.Authenticated then
    Args.Request.Headers.SetValue('Authorization', 'Bearer ' + AuthService.GetToken);
end;

procedure TmodMain.NastaviVoznika(Pin: string);
begin
  VoznikId := 0;
  wdsVozniki.Close;
  wdsVozniki.QueryString := '$filter=Pin eq '''+Pin+'''';
  wdsVozniki.Load;
end;

procedure TmodMain.wdsVoznikiAfterOpen(DataSet: TDataSet);
begin
  if DataSet.RecordCount>0 then begin
    VoznikId := DataSet.FieldByName('Id').AsInteger;
    VoznikUserName := DataSet.FieldByName('UporabniskoIme').AsString;
    VoznikIme := DataSet.FieldByName('Ime').AsString+' '+DataSet.FieldByName('Priimek').AsString;
    Voznik :=  TObject(TXDataWebDataSet(DataSet).CurrentData);

    frmMain.SetVoznikLabel(DataSet.FieldByName('Ime').AsString+' '+DataSet.FieldByName('Priimek').AsString);
  end else begin
    Log('Voznik: Iskanje ni uspelo.');
  end;
end;

procedure TmodMain.NastaviVozilo(Id: integer; PrikaziKm: boolean);
begin
  wdsVozila.Tag := 0;
  if PrikaziKm then
    wdsVozila.Tag := 1;

  wdsVozila.Close;
  wdsVozila.QueryString := '$filter=Id eq '''+Id.ToString+'''&$expand=tipvozila,vrstaodvoza';
  wdsVozila.Load;
end;

procedure TmodMain.wdsVozilaAfterOpen(DataSet: TDataSet);
begin
  if DataSet.RecordCount>0 then begin
    VoziloId := DataSet.FieldByName('Id').AsInteger;
    Vozilo := TObject(TXDataWebDataSet(DataSet).CurrentData);
    VoziloStanjeKm := DataSet.FieldByName('StanjeKm').AsInteger;
    VoziloRegSt := DataSet.FieldByName('RegistrskaSt').AsString;
    VoziloTip := DataSet.FieldByName('TipVozila.Id').AsInteger;

    Log('VoziloId='+VoziloId.ToString);
    Log('VoziloTip='+VoziloTip.ToString);

    SetVar('VoziloId', VoziloId.ToString);
    VoziloKolicina := true;

    frmMain.SetVoziloLabel(DataSet.FieldByName('RegistrskaSt').AsString);

    if wdsVozila.Tag>0 then
      frmMain.ShowKilometri(kmPrijava);  // Preseljeno iz okna za izbiro vozila, ker je async..
  end else begin
    Log('VoziloId: Iskanje ni uspelo za ID='+VoziloId.ToString);
  end;
end;

procedure TmodMain.NastaviDatum(ADatum: TDateTime);
begin
  IzbranDatum := ADatum;
  Log('IzbranDatum='+DateToStr(IzbranDatum));
  IzbranDan := VrniDanVTednu(ADatum); // Pozor, začne z nedeljo
  Log('IzbranDan='+IntToStr(IzbranDan));

  wdsDnevi.Close;
  wdsDnevi.QueryString := '$filter=Id eq '''+IzbranDan.ToString+'''';
  wdsDnevi.Load;
end;

procedure TmodMain.NastaviDatumZgodovina(ADatum: TDateTime);
begin
  ZgodovinaDatum := ADatum;
  Log('ZgodovinaDan='+DateToStr(ZgodovinaDatum));
end;

procedure TmodMain.wdsDneviAfterOpen(DataSet: TDataSet);
begin
  IzbranDanIme := DataSet.FieldByName('Ime').AsString;
  Log('IzbranDanIme='+IzbranDanIme);
  frmMain.SetDanLabel(IzbranDanIme, IzbranDatum);
end;

procedure TmodMain.NastaviFrakcijo(Id: integer);
begin
  FrakcijaId := Id;
  SetVar('FrakcijaId', FrakcijaId.ToString);
  wdsFrakcije.Close;
  wdsFrakcije.QueryString := '$filter=Id eq '''+Id.ToString+'''';
  wdsFrakcije.Load;
end;

procedure TmodMain.wdsFrakcijeAfterOpen(DataSet: TDataSet);
begin
  if DataSet.RecordCount>0 then begin
    FrakcijaId := DataSet.FieldByName('Id').AsInteger;
    Frakcija := TObject(TXDataWebDataSet(DataSet).CurrentData);
    frmMain.SetFrakcijaLabel(DataSet.FieldByName('Opis').AsString);
  end else begin
    Log('FrakcijaId: Iskanje ni uspelo za ID='+FrakcijaId.ToString);
  end;
end;

procedure TmodMain.NastaviLokacijo(Id: integer);
begin
  LokacijaId := Id;
  wdsLokacije.Close;
  wdsLokacije.QueryString := '$filter=Id eq '''+Id.ToString+'''&$expand=stranka';
  wdsLokacije.Load;
end;

procedure TmodMain.wdsLokacijeAfterOpen(DataSet: TDataSet);
begin
  if DataSet.RecordCount>0 then begin
    LokacijaId := DataSet.FieldByName('Id').AsInteger;
    Lokacija := TObject(TXDataWebDataSet(DataSet).CurrentData);
    StrankaId := DataSet.FieldByName('Stranka.Id').AsInteger;
  end else begin
    Log('LokacijaId: Iskanje ni uspelo za ID='+LokacijaId.ToString);
  end;
end;

procedure TmodMain.NastaviMenjavo(Id: integer);
begin
  wdsMenjava.Close;
  wdsMenjava.QueryString := '$filter=Id eq '+Id.ToString;
  wdsMenjava.Load;
end;

procedure TmodMain.wdsMenjavaAfterOpen(DataSet: TDataSet);
begin
  if DataSet.RecordCount>0 then begin
    VoziloMenjavaId := DataSet.FieldByName('Id').AsInteger;
    VoziloMenjavaRegSt := DataSet.FieldByName('RegistrskaSt').AsString;
    VoziloMenjava := TObject(TXDataWebDataSet(DataSet).CurrentData);
    VoziloMenjavaStanjeKm := DataSet.FieldByName('StanjeKm').AsInteger;
    VoziloStanjeKm := DataSet.FieldByName('StanjeKm').AsInteger;
    frmMain.SetVoziloLabel(VoziloRegSt + '*');
    frmMain.ShowKilometri(kmPrijava);
  end else begin
    Log('VoziloMenjavaId: Iskanje ni uspelo za ID='+VoziloId.ToString);
  end;
end;

procedure TmodMain.NastaviPot(APotId: integer);
begin
  PotId := APotId;
  Log('PotId:'+PotId.ToString);
end;

procedure TmodMain.NastaviStevecKm(VoziloId, StevecKm: integer; Opombe: string);
begin
  try
    VoziloStanjeKm := StevecKm;
    Log('Executing NastaviKm: StevecKm: '+ StevecKm.ToString +', VoziloId: '+VoziloId.ToString+', Uporabnik: '+VoznikUserName+', Opombe: '+Opombe);
    cliMain.RawInvoke('IKamijoncinService.NastaviKm', [VoziloId, StevecKm, VoznikUserName, Opombe]);
  except
     on E:Exception do begin
       Log('NastaviKm error: '+E.Message);
     end;
  end;
end;

procedure TmodMain.geoLocGeolocation(Sender: TObject; Lat, Lon, Alt: Double);
var Delta: double;
    Premik: boolean;
begin
  Log('Izračun delta premika');
  Delta := RazdaljaM(Lat, Lon, self.LokacijaLat, self.LokacijaLon);

  HasMoved := Delta > MinDelta;  // vsaj 1 m premika...

  // Prvič shrani ne glede na rezultat
  if self.LokacijaLat=0 then begin
    self.LokacijaLat := Lat;
    self.LokacijaLon := Lon;
  end;

  if Delta < MinDelta then begin  // Manj kot 10m se ne procesira...
    Log('RazdaljaM: Premajhen delta, Delta (m)='+Delta.ToString);
    exit;
  end;

  if MaxDelta<Delta then begin
    Log('RazdaljaM: Prevelik delta, MaxDist='+MaxDelta.ToString+', Delta (m)='+Delta.ToString);
    exit;
  end;

  Log('RazdaljaM: Delta (m)='+Delta.ToString);

  self.LokacijaLat := Lat;
  self.LokacijaLon := Lon;

  // Če želimo procesirati kaj specifičnega v kakem oknu
  frmMain.ProcessLokacija;

  if VoziloId>0 then
    SporociLokacijo(VoziloId, Lat, Lon);
end;

function TmodMain.RazdaljaM(lat1, lon1, lat2, lon2: double): double;
const R = 6371000; // Zemljin R
var DeltaLat, DeltaLon, A, C: Double;

  function toRadians(degrees: double): double;
  begin
    Result := Degrees * (Pi / 180);
  end;

begin
  lat1 := toRadians(lat1);
  lon1 := toRadians(lon1);
  lat2 := toRadians(lat2);
  lon2 := toRadians(lon2);

  // Diff koordinat
  deltaLat := lat2 - lat1;
  deltaLon := lon2 - lon1;

  /// Haversine formula
  A := Sqr(Sin(DeltaLat / 2)) +
       Cos(Lat1) * Cos(Lat2) * Sqr(Sin(DeltaLon / 2));

  C := 2 * ArcTan2(Sqrt(A), Sqrt(1 - A));

  // Razdalja v metrih
  Result := RoundTo(R * C, -2);
end;

procedure TmodMain.geoLocGeolocationError(Sender: TObject; ACode: Integer; AMessage: string);
begin
  Log('(geoLocGeolocationError) '+ACode.ToString+' '+AMessage);
end;

function TmodMain.GetVar(TokenName: string): string;
var
  ls: TLocalStorage;
begin
  Result := '';
  ls := TLocalStorage.Create;
  try
    Result := ls.GetValue(TokenName);
  finally
    ls.free;
  end;
end;

procedure TmodMain.Log(Txt: string);
begin
  if AppSettings.DevMode then
    Console.Log(Txt);
end;

procedure TmodMain.SetVar(TokenName, Value: string);
var
  ls: TLocalStorage;
begin
//  ClearMyToken; // clear it first - not strictly necessary
  ls := TLocalStorage.Create;
  try
    ls.SetValue(TokenName, Value);
  finally
    ls.free;
  end;
end;

procedure TmodMain.SporociLokacijo(AVoziloId: integer; Lat, Lon: double);
begin
  try
    Log('Executing SporociLokacijo');
    cliMain.RawInvoke('IKamijoncinService.JaviPozicijo', [VoziloId, Lat, Lon]);
  except
     // Pač, ni ratalo.. ignoriraj :)
     on E:Exception do begin
       Log('SporociLokacijo error: '+E.Message);
     end;
  end;
end;

procedure TmodMain.UstaviSledenje;
begin
  Log('Stopping tracking...');
  geoLoc.StopTracking;
end;

procedure TmodMain.VpisiGorivo(VoziloId: integer; Litri: double; Opombe: string);
begin
  try
    Log('Executing VpisiGorivo: Litri: '+ Litri.ToString +', VoziloId: '+VoziloId.ToString+', Uporabnik: '+VoznikUserName+', Opombe: '+Opombe);
    cliMain.RawInvoke('IKamijoncinService.VpisiGorivo', [VoziloId, Litri, VoznikUserName, Opombe]);
  except
     on E:Exception do begin
       Log('VpisiGorivo error: '+E.Message);
       frmMain.PrikaziObvestilo('Napaka','Napaka pri vpisu goriva! Kliči podporo!');
     end;
  end;

end;

function TmodMain.VrniDanVTednu(Datum: TDateTime): integer;
begin
  Result := DayOfTheWeek(Datum) + 1;

  if Result > 7 then
    Result := 1;

  Log('DayOfTheWeek(Datum)='+DayOfTheWeek(Datum).ToString);
  Log('VrniDanVTednu, Result='+Result.ToString);
end;

function TmodMain.VrniTipvozila: TVoziloTip;
begin
  Result := TVoziloTip(VoziloTip-1);
end;

procedure TmodMain.TehtajVozilo(VoziloId: integer; VoznikUserName: string);
begin
  try
    Log('Executing TehtajVozilo');
    cliMain.RawInvokeAsync('IKamijoncinService.TehtajVozilo', [VoziloId, FrakcijaId, VoznikUserName]);
  except
     on E:Exception do begin
       Log('TehtajVozilo error: '+E.Message);
       frmMain.PrikaziObvestilo('Napaka','Napaka pri tehtanju vozila! Kliči podporo!');
     end;
  end;
end;

procedure TmodMain.VpisiStoritveCnt(NalogPodrobnoId: integer; Pranje: boolean; LocevanjeUr, LocevanjeDelavci, DvigaloUr: integer);
begin
  try
    Log('Executing VpisiStoritveCnt: NalogPodrobnoId: '+ NalogPodrobnoId.ToString+', Pranje: '+Pranje.ToString+', LocevanjeUr: '+LocevanjeUr.ToString+', LocevanjeDelavci: '+LocevanjeDelavci.ToString+',  DvigaloUr: '+DvigaloUr.ToString);
    cliMain.RawInvoke('IKamijoncinService.VpisiStoritveCnt', [NalogPodrobnoId, Pranje, LocevanjeUr, LocevanjeDelavci, DvigaloUr, VoznikUserName]);
    Log('Executing VpisiStoritveCnt: VEGE');
  except
     on E:Exception do begin
       Log('VpisiTezoCnt error: '+E.Message);
       frmMain.PrikaziObvestilo('Napaka','Napaka pri vpisu storitev! Kliči podporo!');
     end;
  end;
end;

procedure TmodMain.VpisiTezoCnt(NalogPodrobnoId, TezaKg: integer);
begin
  try
    Log('Executing VpisiTezoCnt: NalogPodrobnoId: '+ NalogPodrobnoId.ToString+', TezaKg: '+TezaKg.ToString);
    cliMain.RawInvoke('IKamijoncinService.VpisiTezoCnt', [NalogPodrobnoId, TezaKg, VoznikUserName]);
    Log('Executing VpisiTezoCnt: VEGE');
  except
     on E:Exception do begin
       Log('VpisiTezoCnt error: '+E.Message);
       frmMain.PrikaziObvestilo('Napaka','Napaka pri vpisu teže kontejnerja! Kliči podporo!');
     end;
  end;
end;

function TmodMain.RazloziCnt(NalogPodrobnoId: integer): boolean;
begin
  try
    Log('Executing RazloziCnt: NalogPodrobnoId: '+ NalogPodrobnoId.ToString);
    cliMain.RawInvoke('IKamijoncinService.RazloziCnt', [NalogPodrobnoId, VoznikUserName]);
    Log('Executing RazloziCnt: VEGE');
  except
     on E:Exception do begin
       Result := false;
       Log('RazloziCnt error: '+E.Message);
       frmMain.PrikaziObvestilo('Napaka','Napaka pri razlaganju kontejnerja! Kliči podporo!');
     end;
  end;
end;

function TmodMain.NaloziCnt(NalogPodrobnoId: integer): boolean;
begin
  try
    Log('Executing NaloziCnt: NalogPodrobnoId: '+ NalogPodrobnoId.ToString);
    cliMain.RawInvoke('IKamijoncinService.NaloziCnt', [NalogPodrobnoId, VoznikUserName]);
    Log('Executing NaloziCnt: VEGE');
  except
     on E:Exception do begin
       Result := false;
       Log('NaloziCnt error: '+E.Message);
       frmMain.PrikaziObvestilo('Napaka','Napaka pri nalaganju kontejnerja! Kliči podporo!');
     end;
  end;
end;

function TmodMain.TehtajCnt(NalogPodrobnoId: integer): boolean;
begin
  try
    Log('Executing TehtajCnt: NalogPodrobnoId: '+ NalogPodrobnoId.ToString);
    cliMain.RawInvokeAsync('IKamijoncinService.TehtajCnt', [NalogPodrobnoId, VoznikUserName]);
  except
     on E:Exception do begin
       Log('TehtajCnt error: '+E.Message);
       Result := false;
       frmMain.PrikaziObvestilo('Napaka','Napaka pri tehtanju kontejnerja! Kliči podporo!');
     end;
  end;
end;

procedure TmodMain.NastaviVoznikDostava(NalogPodrobnoId, VoznikId: integer);
begin
  try
    Log('Executing NastaviVoznikDostava: NalogPodrobnoId: '+ NalogPodrobnoId.ToString);
    cliMain.RawInvoke('IKamijoncinService.NastaviVoznikDostava', [NalogPodrobnoId, VoznikId, VoznikUserName]);
    Log('Executing NastaviVoznikDostava: VEGE');
  except
     on E:Exception do begin
       Log('NastaviVoznikDostava error: '+E.Message);
     end;
  end;
end;

procedure TmodMain.NastaviVoznikOdvoz(NalogPodrobnoId, VoznikId: integer);
begin
  try
    Log('Executing NastaviVoznikOdvoz: NalogPodrobnoId: '+ NalogPodrobnoId.ToString);
    cliMain.RawInvoke('IKamijoncinService.NastaviVoznikOdvoz', [NalogPodrobnoId, VoznikId, VoznikUserName]);
    Log('Executing NastaviVoznikOdvoz: VEGE');
  except
     on E:Exception do begin
       Log('NastaviVoznikOdvoz error: '+E.Message);
     end;
  end;
end;

procedure TmodMain.PosljiZahtevekTehtanje(NalogPodrobnoId: integer);
begin
  try
    Log('Executing PosljiZahtevekTehtanje: NalogPodrobnoId: '+ NalogPodrobnoId.ToString);
    cliMain.RawInvoke('IKamijoncinService.PosljiZahtevekTehtanje', [NalogPodrobnoId]);
  except
     on E:Exception do begin
       Log('PosljiZahtevekTehtanje error: '+E.Message);
       frmMain.PrikaziObvestilo('Napaka','Napaka pri tehtanju! Kliči podporo!');
     end;
  end;
end;

procedure TmodMain.PrenesiNalog(NalogId, VoziloId: integer);
begin
  try
    Log('Executing PrenesiNalog: NalogId: '+ NalogId.ToString +', VoziloId: '+VoziloId.ToString);
    cliMain.RawInvoke('IKamijoncinService.PrenesiNalog', [NalogId, VoziloId]);
  except
     on E:Exception do begin
       Log('PrenesiNalog error: '+E.Message);
       frmMain.PrikaziObvestilo('Napaka','Napaka pri prenosu naloga!');
     end;
  end;
end;

procedure TmodMain.ZakljuciNalog(NalogId: integer);
begin
  try
    Log('Executing ZakljuciNalog: NalogId: '+ NalogId.ToString);
    cliMain.RawInvoke('IKamijoncinService.ZakljuciNalog', [NalogId]);
  except
     on E:Exception do begin
       Log('ZakljuciNalog error: '+E.Message);
     end;
  end;
end;

procedure TmodMain.NastaviNalog(ANalogId: integer);
begin
  NalogId := ANalogId;
  Log('NalogId:'+NalogId.ToString);
end;

procedure TmodMain.NastaviTipNalog(TipNalogId: integer);
begin
  TipNalogaId := TipNalogId;
  Log('TipNalogaId:'+TipNalogId.ToString);
end;

procedure TmodMain.SetCoordinate(Lon, Lat: double);
begin
  TargetLat := Lat;
  TargetLon := Lon;
end;

procedure TmodMain.LoadDFMValues;
begin
  inherited LoadDFMValues;

  connServer := TXDataWebConnection.Create(Self);
  wdsVozila := TXDataWebDataSet.Create(Self);
  wdsVozniki := TXDataWebDataSet.Create(Self);
  wdsVoznikiId := TIntegerField.Create(Self);
  wdsVoznikiUporabniskoIme := TStringField.Create(Self);
  wdsVoznikiGeslo := TStringField.Create(Self);
  wdsVoznikiPIN := TStringField.Create(Self);
  wdsVoznikiIme := TStringField.Create(Self);
  wdsVoznikiPriimek := TStringField.Create(Self);
  wdsVoznikiAktiven := TIntegerField.Create(Self);
  wdsFrakcije := TXDataWebDataSet.Create(Self);
  wdsFrakcijeId := TIntegerField.Create(Self);
  wdsFrakcijeOpis := TStringField.Create(Self);
  wdsFrakcijeAktivno := TIntegerField.Create(Self);
  wdsPoti := TXDataWebDataSet.Create(Self);
  wdsLokacije := TXDataWebDataSet.Create(Self);
  wdsMenjava := TXDataWebDataSet.Create(Self);
  wdsDnevi := TXDataWebDataSet.Create(Self);
  cliMain := TXDataWebClient.Create(Self);
  geoLoc := TGeoLocation.Create(Self);

  connServer.BeforeLoadDFMValues;
  wdsVozila.BeforeLoadDFMValues;
  wdsVozniki.BeforeLoadDFMValues;
  wdsVoznikiId.BeforeLoadDFMValues;
  wdsVoznikiUporabniskoIme.BeforeLoadDFMValues;
  wdsVoznikiGeslo.BeforeLoadDFMValues;
  wdsVoznikiPIN.BeforeLoadDFMValues;
  wdsVoznikiIme.BeforeLoadDFMValues;
  wdsVoznikiPriimek.BeforeLoadDFMValues;
  wdsVoznikiAktiven.BeforeLoadDFMValues;
  wdsFrakcije.BeforeLoadDFMValues;
  wdsFrakcijeId.BeforeLoadDFMValues;
  wdsFrakcijeOpis.BeforeLoadDFMValues;
  wdsFrakcijeAktivno.BeforeLoadDFMValues;
  wdsPoti.BeforeLoadDFMValues;
  wdsLokacije.BeforeLoadDFMValues;
  wdsMenjava.BeforeLoadDFMValues;
  wdsDnevi.BeforeLoadDFMValues;
  cliMain.BeforeLoadDFMValues;
  geoLoc.BeforeLoadDFMValues;
  try
    Name := 'modMain';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    Height := 423;
    Width := 594;
    connServer.SetParentComponent(Self);
    connServer.Name := 'connServer';
    connServer.URL := 'http://localhost:2001/kamijoncin';
    SetEvent(connServer, Self, 'OnConnect', 'connServerConnect');
    SetEvent(connServer, Self, 'OnError', 'connServerError');
    SetEvent(connServer, Self, 'OnRequest', 'connServerRequest');
    connServer.Left := 32;
    connServer.Top := 8;
    wdsVozila.SetParentComponent(Self);
    wdsVozila.Name := 'wdsVozila';
    wdsVozila.AfterOpen := wdsVozilaAfterOpen;
    wdsVozila.EntitySetName := 'Vozilo';
    wdsVozila.Connection := connServer;
    wdsVozila.SubpropsDepth := 1;
    wdsVozila.Left := 194;
    wdsVozila.Top := 64;
    wdsVozniki.SetParentComponent(Self);
    wdsVozniki.Name := 'wdsVozniki';
    wdsVozniki.AfterOpen := wdsVoznikiAfterOpen;
    wdsVozniki.EntitySetName := 'Uporabnik';
    wdsVozniki.Connection := connServer;
    wdsVozniki.QueryTop := 1;
    wdsVozniki.Left := 114;
    wdsVozniki.Top := 8;
    wdsVoznikiId.SetParentComponent(wdsVozniki);
    wdsVoznikiId.Name := 'wdsVoznikiId';
    wdsVoznikiId.FieldName := 'Id';
    wdsVoznikiId.Required := True;
    wdsVoznikiUporabniskoIme.SetParentComponent(wdsVozniki);
    wdsVoznikiUporabniskoIme.Name := 'wdsVoznikiUporabniskoIme';
    wdsVoznikiUporabniskoIme.FieldName := 'UporabniskoIme';
    wdsVoznikiUporabniskoIme.Required := True;
    wdsVoznikiUporabniskoIme.Size := 50;
    wdsVoznikiGeslo.SetParentComponent(wdsVozniki);
    wdsVoznikiGeslo.Name := 'wdsVoznikiGeslo';
    wdsVoznikiGeslo.FieldName := 'Geslo';
    wdsVoznikiGeslo.Required := True;
    wdsVoznikiGeslo.Size := 50;
    wdsVoznikiPIN.SetParentComponent(wdsVozniki);
    wdsVoznikiPIN.Name := 'wdsVoznikiPIN';
    wdsVoznikiPIN.FieldName := 'PIN';
    wdsVoznikiPIN.Size := 10;
    wdsVoznikiIme.SetParentComponent(wdsVozniki);
    wdsVoznikiIme.Name := 'wdsVoznikiIme';
    wdsVoznikiIme.FieldName := 'Ime';
    wdsVoznikiIme.Required := True;
    wdsVoznikiIme.Size := 50;
    wdsVoznikiPriimek.SetParentComponent(wdsVozniki);
    wdsVoznikiPriimek.Name := 'wdsVoznikiPriimek';
    wdsVoznikiPriimek.FieldName := 'Priimek';
    wdsVoznikiPriimek.Required := True;
    wdsVoznikiPriimek.Size := 50;
    wdsVoznikiAktiven.SetParentComponent(wdsVozniki);
    wdsVoznikiAktiven.Name := 'wdsVoznikiAktiven';
    wdsVoznikiAktiven.FieldName := 'Aktiven';
    wdsVoznikiAktiven.Required := True;
    wdsFrakcije.SetParentComponent(Self);
    wdsFrakcije.Name := 'wdsFrakcije';
    wdsFrakcije.AfterOpen := wdsFrakcijeAfterOpen;
    wdsFrakcije.EntitySetName := 'Frakcija';
    wdsFrakcije.Connection := connServer;
    wdsFrakcije.Left := 112;
    wdsFrakcije.Top := 64;
    wdsFrakcijeId.SetParentComponent(wdsFrakcije);
    wdsFrakcijeId.Name := 'wdsFrakcijeId';
    wdsFrakcijeId.FieldName := 'Id';
    wdsFrakcijeId.Required := True;
    wdsFrakcijeOpis.SetParentComponent(wdsFrakcije);
    wdsFrakcijeOpis.Name := 'wdsFrakcijeOpis';
    wdsFrakcijeOpis.FieldName := 'Opis';
    wdsFrakcijeOpis.Required := True;
    wdsFrakcijeOpis.Size := 50;
    wdsFrakcijeAktivno.SetParentComponent(wdsFrakcije);
    wdsFrakcijeAktivno.Name := 'wdsFrakcijeAktivno';
    wdsFrakcijeAktivno.FieldName := 'Aktivno';
    wdsFrakcijeAktivno.Required := True;
    wdsPoti.SetParentComponent(Self);
    wdsPoti.Name := 'wdsPoti';
    wdsPoti.EntitySetName := 'Pot';
    wdsPoti.Connection := connServer;
    wdsPoti.Left := 264;
    wdsPoti.Top := 8;
    wdsLokacije.SetParentComponent(Self);
    wdsLokacije.Name := 'wdsLokacije';
    wdsLokacije.AfterOpen := wdsLokacijeAfterOpen;
    wdsLokacije.EntitySetName := 'Lokacija';
    wdsLokacije.Connection := connServer;
    wdsLokacije.SubpropsDepth := 1;
    wdsLokacije.Left := 192;
    wdsLokacije.Top := 8;
    wdsMenjava.SetParentComponent(Self);
    wdsMenjava.Name := 'wdsMenjava';
    wdsMenjava.AfterOpen := wdsMenjavaAfterOpen;
    wdsMenjava.EntitySetName := 'Vozilo';
    wdsMenjava.Connection := connServer;
    wdsMenjava.SubpropsDepth := 1;
    wdsMenjava.Left := 266;
    wdsMenjava.Top := 64;
    wdsDnevi.SetParentComponent(Self);
    wdsDnevi.Name := 'wdsDnevi';
    wdsDnevi.AfterOpen := wdsDneviAfterOpen;
    wdsDnevi.EntitySetName := 'Dan';
    wdsDnevi.Connection := connServer;
    wdsDnevi.SubpropsDepth := 1;
    wdsDnevi.Left := 346;
    wdsDnevi.Top := 8;
    cliMain.SetParentComponent(Self);
    cliMain.Name := 'cliMain';
    cliMain.Connection := connServer;
    cliMain.Left := 32;
    cliMain.Top := 62;
    geoLoc.SetParentComponent(Self);
    geoLoc.Name := 'geoLoc';
    geoLoc.HighPrecision := True;
    geoLoc.TimeOut := 4000;
    SetEvent(geoLoc, Self, 'OnGeolocation', 'geoLocGeolocation');
    SetEvent(geoLoc, Self, 'OnGeolocationError', 'geoLocGeolocationError');
    SetEvent(geoLoc, Self, 'OnGeolocationUpdate', 'geoLocGeolocation');
    geoLoc.Left := 32;
    geoLoc.Top := 127;
  finally
    connServer.AfterLoadDFMValues;
    wdsVozila.AfterLoadDFMValues;
    wdsVozniki.AfterLoadDFMValues;
    wdsVoznikiId.AfterLoadDFMValues;
    wdsVoznikiUporabniskoIme.AfterLoadDFMValues;
    wdsVoznikiGeslo.AfterLoadDFMValues;
    wdsVoznikiPIN.AfterLoadDFMValues;
    wdsVoznikiIme.AfterLoadDFMValues;
    wdsVoznikiPriimek.AfterLoadDFMValues;
    wdsVoznikiAktiven.AfterLoadDFMValues;
    wdsFrakcije.AfterLoadDFMValues;
    wdsFrakcijeId.AfterLoadDFMValues;
    wdsFrakcijeOpis.AfterLoadDFMValues;
    wdsFrakcijeAktivno.AfterLoadDFMValues;
    wdsPoti.AfterLoadDFMValues;
    wdsLokacije.AfterLoadDFMValues;
    wdsMenjava.AfterLoadDFMValues;
    wdsDnevi.AfterLoadDFMValues;
    cliMain.AfterLoadDFMValues;
    geoLoc.AfterLoadDFMValues;
  end;
end;

end.
