unit Storitve;

interface

uses
  System.SysUtils, System.Classes, Vcl.Forms, JS, Web, WEBLib.Graphics, WEBLib.Controls, WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, TerminalForm,
  Vcl.Controls, Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Connection, WEBLib.ExtCtrls, WEBLib.DB, WEBLib.DBCtrls, WEBLib.Grids,
  WEBLib.Buttons;

type
  TfrmStoritve = class(TTerminalForm)
    panFooter: TPanel;
    btnOk: TButton;
    btnCancel: TButton;
    tmrStart: TTimer;
    lblTitle: TLabel;
    WebPanel1: TPanel;
    btnUrInc: TButton;
    edtSortUr: TDBEdit;
    btnUrDec: TButton;
    WebLabel1: TLabel;
    panSortDel: TPanel;
    panDvigaloUr: TPanel;
    btnDelInc: TButton;
    edtSortDel: TDBEdit;
    btnDelDec: TButton;
    WebLabel2: TLabel;
    btnDvigInc: TButton;
    edtDvigaloUr: TDBEdit;
    btnDvigDec: TButton;
    WebLabel3: TLabel;
    wdsPodrobno: TXDataWebDataSet;
    dsPodrobno: TDataSource;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebLabel8: TLabel;
    WebLabel9: TLabel;
    WebPanel2: TPanel;
    WebLabel13: TLabel;
    WebLabel14: TLabel;
    WebLabel15: TLabel;
    wdsPodrobnoId: TIntegerField;
    wdsPodrobnoPranje: TBooleanField;
    wdsPodrobnoLocevanjeUr: TIntegerField;
    wdsPodrobnoLocevanjeDelavci: TIntegerField;
    wdsPodrobnoStojnina: TBooleanField;
    wdsPodrobnoTeza: TIntegerField;
    btnPonovo: TButton;
    WebPanel3: TPanel;
    WebLabel10: TLabel;
    WebLabel11: TLabel;
    WebLabel12: TLabel;
    btnPonovitev: TButton;
    wdsPodrobnoNalogVoziloTipVozilaId: TIntegerField;
    chxPranje: TToggleButton;
    wdsPodrobnoDvigaloUr: TIntegerField;
    procedure FormShow(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure btnCancelClick(Sender: TObject);
    procedure tmrStartTimer(Sender: TObject);
    procedure btnUrIncClick(Sender: TObject);
    procedure btnDelIncClick(Sender: TObject);
    procedure btnDvigIncClick(Sender: TObject);
    procedure btnUrDecClick(Sender: TObject);
    procedure btnDelDecClick(Sender: TObject);
    procedure btnDvigDecClick(Sender: TObject);
    procedure wdsPodrobnoAfterOpen(DataSet: TDataSet);
    procedure btnOkClick(Sender: TObject);
  private
    { Private declarations }
    procedure ToggleOk(Enabled: boolean);

    procedure IncValue(Edit: TDBEdit; Delta: integer);
    procedure DecValue(Edit: TDBEdit; Delta: integer);
    procedure ToggleDvigalo(IsVisible: boolean);
    procedure ApplyTheme(Dark: boolean); reintroduce;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmStoritve: TfrmStoritve;

implementation

{$R *.dfm}

uses Main, Client.Core, Client.Settings, Auth.Service, Client.Constants;

procedure TfrmStoritve.btnDelDecClick(Sender: TObject);
begin
  DecValue(edtSortDel, 1);
end;

procedure TfrmStoritve.btnDelIncClick(Sender: TObject);
begin
  IncValue(edtSortDel, 1);
end;

procedure TfrmStoritve.btnDvigDecClick(Sender: TObject);
begin
  DecValue(edtDvigaloUr, 1);
end;

procedure TfrmStoritve.btnDvigIncClick(Sender: TObject);
begin
  IncValue(edtDvigaloUr, 1);
end;

procedure TfrmStoritve.btnOkClick(Sender: TObject);
begin
  modMain.VpisiStoritveCnt(wdsPodrobno.FieldByName('Id').AsInteger, chxPranje.Checked, wdsPodrobno.FieldByName('LocevanjeUr').AsInteger, wdsPodrobno.FieldByName('LocevanjeDelavci').AsInteger, wdsPodrobno.FieldByName('DvigaloUr').AsInteger);
  modMain.TehtajCnt(wdsPodrobno.FieldByName('Id').AsInteger);
  frmMain.ShowNalog;
end;

procedure TfrmStoritve.btnUrDecClick(Sender: TObject);
begin
  DecValue(edtSortUr, 1);
end;

procedure TfrmStoritve.btnUrIncClick(Sender: TObject);
begin
  IncValue(edtSortUr, 1);
end;

procedure TfrmStoritve.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Action := caFree;
end;

procedure TfrmStoritve.btnCancelClick(Sender: TObject);
begin
  frmMain.ShowPrevious;
end;

procedure TfrmStoritve.DecValue(Edit: TDBEdit; Delta: integer);
var val: integer;
begin
  if Edit.DataSource.DataSet.FieldByName(Edit.DataField).AsInteger <= 0 then
    exit;

  Edit.DataSource.DataSet.Edit;
  Edit.DataSource.DataSet.FieldByName(Edit.DataField).AsInteger := Edit.DataSource.DataSet.FieldByName(Edit.DataField).AsInteger - Delta;
end;

procedure TfrmStoritve.IncValue(Edit: TDBEdit; Delta: integer);
begin
  modMain.Log('Edit.DataField: '+Edit.DataField);
  Edit.DataSource.DataSet.Edit;
  Edit.DataSource.DataSet.FieldByName(Edit.DataField).AsInteger := Edit.DataSource.DataSet.FieldByName(Edit.DataField).AsInteger + Delta;
end;

procedure TfrmStoritve.FormShow(Sender: TObject);
begin
  inherited;
  
  ApplyTheme(AppSettings.DarkMode);

  wdsPodrobno.Close;
  wdsPodrobno.QueryString := '$filter=Id eq '+modMain.NalogPodrobnoId.ToString+'&$expand=nalog/vozilo/tipvozila';
  wdsPodrobno.Load;
end;

procedure TfrmStoritve.tmrStartTimer(Sender: TObject);
begin
  tmrStart.Enabled := false;
end;

procedure TfrmStoritve.ToggleDvigalo(IsVisible: boolean);
begin
  panDvigaloUr.Visible := IsVisible;
end;

procedure TfrmStoritve.ToggleOk(Enabled: boolean);
begin
  btnOk.Enabled := Enabled;
  btnPonovo.Enabled := btnOk.Enabled;
end;

procedure TfrmStoritve.wdsPodrobnoAfterOpen(DataSet: TDataSet);
begin
  ToggleOk(true);
  modMain.Log('Nalog.Vozilo.TipVozila.Id='+wdsPodrobno.FieldByName('Nalog.Vozilo.TipVozila.Id').AsString);

  ToggleDvigalo(wdsPodrobno.FieldByName('Nalog.Vozilo.TipVozila.Id').AsInteger = Ord(tvZDvigalom));
end;

procedure TfrmStoritve.ApplyTheme(Dark: boolean);
begin
  inherited;

  if Dark then begin
    weblabel1.ElementClassName := 'display-3 text-white';
    weblabel2.ElementClassName := 'display-3 text-white';
    weblabel3.ElementClassName := 'display-3 text-white';
    weblabel13.ElementClassName := 'display-3 text-white';
  end;
end;

procedure TfrmStoritve.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lblTitle := TLabel.Create(Self);
  panFooter := TPanel.Create(Self);
  btnOk := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  btnPonovo := TButton.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create('lblTitle');
  WebLabel9 := TLabel.Create('lblTitle');
  btnUrInc := TButton.Create(Self);
  edtSortUr := TDBEdit.Create(Self);
  btnUrDec := TButton.Create(Self);
  panSortDel := TPanel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create('lblTitle');
  WebLabel7 := TLabel.Create('lblTitle');
  btnDelInc := TButton.Create(Self);
  edtSortDel := TDBEdit.Create(Self);
  btnDelDec := TButton.Create(Self);
  panDvigaloUr := TPanel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create('lblTitle');
  WebLabel5 := TLabel.Create('lblTitle');
  btnDvigInc := TButton.Create(Self);
  edtDvigaloUr := TDBEdit.Create(Self);
  btnDvigDec := TButton.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebLabel14 := TLabel.Create('lblTitle');
  WebLabel15 := TLabel.Create('lblTitle');
  chxPranje := TToggleButton.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create('lblTitle');
  WebLabel12 := TLabel.Create('lblTitle');
  btnPonovitev := TButton.Create(Self);
  tmrStart := TTimer.Create(Self);
  wdsPodrobno := TXDataWebDataSet.Create(Self);
  wdsPodrobnoId := TIntegerField.Create(Self);
  wdsPodrobnoNalogVoziloTipVozilaId := TIntegerField.Create(Self);
  wdsPodrobnoPranje := TBooleanField.Create(Self);
  wdsPodrobnoLocevanjeUr := TIntegerField.Create(Self);
  wdsPodrobnoLocevanjeDelavci := TIntegerField.Create(Self);
  wdsPodrobnoDvigaloUr := TIntegerField.Create(Self);
  wdsPodrobnoStojnina := TBooleanField.Create(Self);
  wdsPodrobnoTeza := TIntegerField.Create(Self);
  dsPodrobno := TDataSource.Create(Self);

  lblTitle.BeforeLoadDFMValues;
  panFooter.BeforeLoadDFMValues;
  btnOk.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  btnPonovo.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  btnUrInc.BeforeLoadDFMValues;
  edtSortUr.BeforeLoadDFMValues;
  btnUrDec.BeforeLoadDFMValues;
  panSortDel.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  btnDelInc.BeforeLoadDFMValues;
  edtSortDel.BeforeLoadDFMValues;
  btnDelDec.BeforeLoadDFMValues;
  panDvigaloUr.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  btnDvigInc.BeforeLoadDFMValues;
  edtDvigaloUr.BeforeLoadDFMValues;
  btnDvigDec.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  chxPranje.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  btnPonovitev.BeforeLoadDFMValues;
  tmrStart.BeforeLoadDFMValues;
  wdsPodrobno.BeforeLoadDFMValues;
  wdsPodrobnoId.BeforeLoadDFMValues;
  wdsPodrobnoNalogVoziloTipVozilaId.BeforeLoadDFMValues;
  wdsPodrobnoPranje.BeforeLoadDFMValues;
  wdsPodrobnoLocevanjeUr.BeforeLoadDFMValues;
  wdsPodrobnoLocevanjeDelavci.BeforeLoadDFMValues;
  wdsPodrobnoDvigaloUr.BeforeLoadDFMValues;
  wdsPodrobnoStojnina.BeforeLoadDFMValues;
  wdsPodrobnoTeza.BeforeLoadDFMValues;
  dsPodrobno.BeforeLoadDFMValues;
  try
    Name := 'frmStoritve';
    Left := 0;
    Top := 0;
    Color := clBtnFace;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -27;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnShow', 'FormShow');
    lblTitle.SetParentComponent(Self);
    lblTitle.Name := 'lblTitle';
    lblTitle.Left := 0;
    lblTitle.Top := 0;
    lblTitle.Width := 644;
    lblTitle.Height := 88;
    lblTitle.Margins.Left := 10;
    lblTitle.Margins.Right := 10;
    lblTitle.Align := alTop;
    lblTitle.Alignment := taCenter;
    lblTitle.AutoSize := False;
    lblTitle.Caption := 'Storitve';
    lblTitle.Color := clBtnFace;
    lblTitle.ElementClassName := 'display-1 text-danger';
    lblTitle.ElementFont := efCSS;
    lblTitle.HeightPercent := 100.000000000000000000;
    lblTitle.WidthPercent := 100.000000000000000000;
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 648;
    panFooter.Width := 644;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.ElementFont := efCSS;
    panFooter.Padding.Top := 5;
    panFooter.TabOrder := 0;
    btnOk.SetParentComponent(panFooter);
    btnOk.Name := 'btnOk';
    btnOk.AlignWithMargins := True;
    btnOk.Left := 469;
    btnOk.Top := 10;
    btnOk.Width := 165;
    btnOk.Height := 107;
    btnOk.Margins.Top := 5;
    btnOk.Margins.Right := 10;
    btnOk.Align := alRight;
    btnOk.Caption := '<i class="material-icons" style="font-size:40px;">done</i>';
    btnOk.ChildOrder := 1;
    btnOk.ElementClassName := 'btn btn-success';
    btnOk.ElementFont := efCSS;
    btnOk.Enabled := False;
    btnOk.HeightPercent := 100.000000000000000000;
    btnOk.WidthPercent := 100.000000000000000000;
    SetEvent(btnOk, Self, 'OnClick', 'btnOkClick');
    btnCancel.SetParentComponent(panFooter);
    btnCancel.Name := 'btnCancel';
    btnCancel.AlignWithMargins := True;
    btnCancel.Left := 10;
    btnCancel.Top := 8;
    btnCancel.Width := 111;
    btnCancel.Height := 109;
    btnCancel.Margins.Left := 10;
    btnCancel.Margins.Right := 10;
    btnCancel.Align := alLeft;
    btnCancel.Caption := '<i class="material-icons" style="font-size:40px;">keyboard_backspace</i>';
    btnCancel.ChildOrder := 1;
    btnCancel.ElementClassName := 'btn btn-danger';
    btnCancel.ElementFont := efCSS;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
    btnPonovo.SetParentComponent(panFooter);
    btnPonovo.Name := 'btnPonovo';
    btnPonovo.AlignWithMargins := True;
    btnPonovo.Left := 345;
    btnPonovo.Top := 10;
    btnPonovo.Width := 111;
    btnPonovo.Height := 107;
    btnPonovo.Margins.Top := 5;
    btnPonovo.Margins.Right := 10;
    btnPonovo.Align := alRight;
    btnPonovo.Caption := '<i class="material-icons" style="font-size:40px;">repeat</i>';
    btnPonovo.ChildOrder := 1;
    btnPonovo.ElementClassName := 'btn btn-success';
    btnPonovo.ElementFont := efCSS;
    btnPonovo.Enabled := False;
    btnPonovo.HeightPercent := 100.000000000000000000;
    btnPonovo.Visible := False;
    btnPonovo.WidthPercent := 100.000000000000000000;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 170;
    WebPanel1.Width := 644;
    WebPanel1.Height := 82;
    WebPanel1.Align := alTop;
    WebPanel1.BorderStyle := bsNone;
    WebPanel1.ChildOrder := 14;
    WebPanel1.Color := clWhite;
    WebPanel1.Padding.Left := 5;
    WebPanel1.Padding.Top := 5;
    WebPanel1.Padding.Right := 5;
    WebPanel1.Padding.Bottom := 5;
    WebPanel1.TabOrder := 1;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 5;
    WebLabel1.Top := 5;
    WebLabel1.Width := 330;
    WebLabel1.Height := 72;
    WebLabel1.Margins.Left := 10;
    WebLabel1.Margins.Right := 10;
    WebLabel1.Align := alClient;
    WebLabel1.AutoSize := False;
    WebLabel1.Caption := 'Lo'#269'evanje ur';
    WebLabel1.Color := clBtnFace;
    WebLabel1.ElementClassName := 'display-3';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(WebPanel1);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 545;
    WebLabel8.Top := 5;
    WebLabel8.Width := 5;
    WebLabel8.Height := 72;
    WebLabel8.Margins.Left := 10;
    WebLabel8.Margins.Right := 10;
    WebLabel8.Align := alRight;
    WebLabel8.AutoSize := False;
    WebLabel8.Color := clBtnFace;
    WebLabel8.ElementClassName := 'display-3';
    WebLabel8.ElementFont := efCSS;
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebLabel9.SetParentComponent(WebPanel1);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 424;
    WebLabel9.Top := 5;
    WebLabel9.Width := 5;
    WebLabel9.Height := 72;
    WebLabel9.Margins.Left := 10;
    WebLabel9.Margins.Right := 10;
    WebLabel9.Align := alRight;
    WebLabel9.AutoSize := False;
    WebLabel9.Color := clBtnFace;
    WebLabel9.ElementClassName := 'display-3';
    WebLabel9.ElementFont := efCSS;
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.WidthPercent := 100.000000000000000000;
    btnUrInc.SetParentComponent(WebPanel1);
    btnUrInc.Name := 'btnUrInc';
    btnUrInc.Left := 550;
    btnUrInc.Top := 5;
    btnUrInc.Width := 89;
    btnUrInc.Height := 72;
    btnUrInc.Align := alRight;
    btnUrInc.Caption := '<i class="material-icons" style="font-size:40px;">add</i>';
    btnUrInc.ChildOrder := 6;
    btnUrInc.ElementClassName := 'btn btn-danger';
    btnUrInc.ElementFont := efCSS;
    btnUrInc.HeightPercent := 100.000000000000000000;
    btnUrInc.WidthPercent := 100.000000000000000000;
    SetEvent(btnUrInc, Self, 'OnClick', 'btnUrIncClick');
    edtSortUr.SetParentComponent(WebPanel1);
    edtSortUr.Name := 'edtSortUr';
    edtSortUr.Left := 429;
    edtSortUr.Top := 5;
    edtSortUr.Width := 116;
    edtSortUr.Height := 72;
    edtSortUr.Align := alRight;
    edtSortUr.Alignment := taCenter;
    edtSortUr.ChildOrder := 5;
    edtSortUr.EditType := weNumeric;
    edtSortUr.ElementClassName := 'display-3';
    edtSortUr.ElementFont := efCSS;
    edtSortUr.HeightPercent := 100.000000000000000000;
    edtSortUr.ReadOnly := True;
    edtSortUr.Text := '0';
    edtSortUr.WidthPercent := 100.000000000000000000;
    edtSortUr.DataField := 'LocevanjeUr';
    edtSortUr.DataSource := dsPodrobno;
    btnUrDec.SetParentComponent(WebPanel1);
    btnUrDec.Name := 'btnUrDec';
    btnUrDec.Left := 335;
    btnUrDec.Top := 5;
    btnUrDec.Width := 89;
    btnUrDec.Height := 72;
    btnUrDec.Align := alRight;
    btnUrDec.Caption := '<i class="material-icons" style="font-size:40px;">remove</i>';
    btnUrDec.ChildOrder := 6;
    btnUrDec.ElementClassName := 'btn btn-success';
    btnUrDec.ElementFont := efCSS;
    btnUrDec.HeightPercent := 100.000000000000000000;
    btnUrDec.WidthPercent := 100.000000000000000000;
    SetEvent(btnUrDec, Self, 'OnClick', 'btnUrDecClick');
    panSortDel.SetParentComponent(Self);
    panSortDel.Name := 'panSortDel';
    panSortDel.Left := 0;
    panSortDel.Top := 252;
    panSortDel.Width := 644;
    panSortDel.Height := 86;
    panSortDel.Align := alTop;
    panSortDel.ChildOrder := 11;
    panSortDel.Color := clWhite;
    panSortDel.Padding.Left := 5;
    panSortDel.Padding.Top := 5;
    panSortDel.Padding.Right := 5;
    panSortDel.Padding.Bottom := 5;
    panSortDel.TabOrder := 2;
    WebLabel2.SetParentComponent(panSortDel);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 5;
    WebLabel2.Top := 5;
    WebLabel2.Width := 330;
    WebLabel2.Height := 76;
    WebLabel2.Margins.Left := 10;
    WebLabel2.Margins.Right := 10;
    WebLabel2.Align := alClient;
    WebLabel2.AutoSize := False;
    WebLabel2.Caption := 'Lo'#269'evanje del.';
    WebLabel2.Color := clBtnFace;
    WebLabel2.ElementClassName := 'display-3';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(panSortDel);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 424;
    WebLabel6.Top := 5;
    WebLabel6.Width := 5;
    WebLabel6.Height := 76;
    WebLabel6.Margins.Left := 10;
    WebLabel6.Margins.Right := 10;
    WebLabel6.Align := alRight;
    WebLabel6.AutoSize := False;
    WebLabel6.Color := clBtnFace;
    WebLabel6.ElementClassName := 'display-3';
    WebLabel6.ElementFont := efCSS;
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(panSortDel);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 545;
    WebLabel7.Top := 5;
    WebLabel7.Width := 5;
    WebLabel7.Height := 76;
    WebLabel7.Margins.Left := 10;
    WebLabel7.Margins.Right := 10;
    WebLabel7.Align := alRight;
    WebLabel7.AutoSize := False;
    WebLabel7.Color := clBtnFace;
    WebLabel7.ElementClassName := 'display-3';
    WebLabel7.ElementFont := efCSS;
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.WidthPercent := 100.000000000000000000;
    btnDelInc.SetParentComponent(panSortDel);
    btnDelInc.Name := 'btnDelInc';
    btnDelInc.Left := 550;
    btnDelInc.Top := 5;
    btnDelInc.Width := 89;
    btnDelInc.Height := 76;
    btnDelInc.Align := alRight;
    btnDelInc.Caption := '<i class="material-icons" style="font-size:40px;">add</i>';
    btnDelInc.ChildOrder := 6;
    btnDelInc.ElementClassName := 'btn btn-danger';
    btnDelInc.ElementFont := efCSS;
    btnDelInc.HeightPercent := 100.000000000000000000;
    btnDelInc.WidthPercent := 100.000000000000000000;
    SetEvent(btnDelInc, Self, 'OnClick', 'btnDelIncClick');
    edtSortDel.SetParentComponent(panSortDel);
    edtSortDel.Name := 'edtSortDel';
    edtSortDel.Left := 429;
    edtSortDel.Top := 5;
    edtSortDel.Width := 116;
    edtSortDel.Height := 76;
    edtSortDel.Align := alRight;
    edtSortDel.Alignment := taCenter;
    edtSortDel.ChildOrder := 5;
    edtSortDel.EditType := weNumeric;
    edtSortDel.ElementClassName := 'display-3';
    edtSortDel.ElementFont := efCSS;
    edtSortDel.HeightPercent := 100.000000000000000000;
    edtSortDel.ReadOnly := True;
    edtSortDel.Text := '0';
    edtSortDel.WidthPercent := 100.000000000000000000;
    edtSortDel.DataField := 'LocevanjeDelavci';
    edtSortDel.DataSource := dsPodrobno;
    btnDelDec.SetParentComponent(panSortDel);
    btnDelDec.Name := 'btnDelDec';
    btnDelDec.Left := 335;
    btnDelDec.Top := 5;
    btnDelDec.Width := 89;
    btnDelDec.Height := 76;
    btnDelDec.Align := alRight;
    btnDelDec.Caption := '<i class="material-icons" style="font-size:40px;">remove</i>';
    btnDelDec.ChildOrder := 6;
    btnDelDec.ElementClassName := 'btn btn-success';
    btnDelDec.ElementFont := efCSS;
    btnDelDec.HeightPercent := 100.000000000000000000;
    btnDelDec.WidthPercent := 100.000000000000000000;
    SetEvent(btnDelDec, Self, 'OnClick', 'btnDelDecClick');
    panDvigaloUr.SetParentComponent(Self);
    panDvigaloUr.Name := 'panDvigaloUr';
    panDvigaloUr.Left := 0;
    panDvigaloUr.Top := 338;
    panDvigaloUr.Width := 644;
    panDvigaloUr.Height := 86;
    panDvigaloUr.Align := alTop;
    panDvigaloUr.BorderColor := clWhite;
    panDvigaloUr.BorderStyle := bsNone;
    panDvigaloUr.ChildOrder := 11;
    panDvigaloUr.Color := clWhite;
    panDvigaloUr.Padding.Left := 5;
    panDvigaloUr.Padding.Top := 5;
    panDvigaloUr.Padding.Right := 5;
    panDvigaloUr.Padding.Bottom := 5;
    panDvigaloUr.TabOrder := 3;
    panDvigaloUr.Visible := False;
    WebLabel3.SetParentComponent(panDvigaloUr);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 5;
    WebLabel3.Top := 5;
    WebLabel3.Width := 330;
    WebLabel3.Height := 76;
    WebLabel3.Margins.Left := 10;
    WebLabel3.Margins.Right := 10;
    WebLabel3.Align := alClient;
    WebLabel3.AutoSize := False;
    WebLabel3.Caption := 'Dvigalo ur';
    WebLabel3.Color := clBtnFace;
    WebLabel3.ElementClassName := 'display-3';
    WebLabel3.ElementFont := efCSS;
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(panDvigaloUr);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 424;
    WebLabel4.Top := 5;
    WebLabel4.Width := 5;
    WebLabel4.Height := 76;
    WebLabel4.Margins.Left := 10;
    WebLabel4.Margins.Right := 10;
    WebLabel4.Align := alRight;
    WebLabel4.AutoSize := False;
    WebLabel4.Color := clBtnFace;
    WebLabel4.ElementClassName := 'display-3';
    WebLabel4.ElementFont := efCSS;
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(panDvigaloUr);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 545;
    WebLabel5.Top := 5;
    WebLabel5.Width := 5;
    WebLabel5.Height := 76;
    WebLabel5.Margins.Left := 10;
    WebLabel5.Margins.Right := 10;
    WebLabel5.Align := alRight;
    WebLabel5.AutoSize := False;
    WebLabel5.Color := clBtnFace;
    WebLabel5.ElementClassName := 'display-3';
    WebLabel5.ElementFont := efCSS;
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    btnDvigInc.SetParentComponent(panDvigaloUr);
    btnDvigInc.Name := 'btnDvigInc';
    btnDvigInc.Left := 550;
    btnDvigInc.Top := 5;
    btnDvigInc.Width := 89;
    btnDvigInc.Height := 76;
    btnDvigInc.Align := alRight;
    btnDvigInc.Caption := '<i class="material-icons" style="font-size:40px;">add</i>';
    btnDvigInc.ChildOrder := 6;
    btnDvigInc.ElementClassName := 'btn btn-danger';
    btnDvigInc.ElementFont := efCSS;
    btnDvigInc.HeightPercent := 100.000000000000000000;
    btnDvigInc.WidthPercent := 100.000000000000000000;
    SetEvent(btnDvigInc, Self, 'OnClick', 'btnDvigIncClick');
    edtDvigaloUr.SetParentComponent(panDvigaloUr);
    edtDvigaloUr.Name := 'edtDvigaloUr';
    edtDvigaloUr.Left := 429;
    edtDvigaloUr.Top := 5;
    edtDvigaloUr.Width := 116;
    edtDvigaloUr.Height := 76;
    edtDvigaloUr.Align := alRight;
    edtDvigaloUr.Alignment := taCenter;
    edtDvigaloUr.ChildOrder := 5;
    edtDvigaloUr.EditType := weNumeric;
    edtDvigaloUr.ElementClassName := 'display-3';
    edtDvigaloUr.ElementFont := efCSS;
    edtDvigaloUr.HeightPercent := 100.000000000000000000;
    edtDvigaloUr.ReadOnly := True;
    edtDvigaloUr.Text := '0';
    edtDvigaloUr.WidthPercent := 100.000000000000000000;
    edtDvigaloUr.DataField := 'DvigaloUr';
    edtDvigaloUr.DataSource := dsPodrobno;
    btnDvigDec.SetParentComponent(panDvigaloUr);
    btnDvigDec.Name := 'btnDvigDec';
    btnDvigDec.Left := 335;
    btnDvigDec.Top := 5;
    btnDvigDec.Width := 89;
    btnDvigDec.Height := 76;
    btnDvigDec.Align := alRight;
    btnDvigDec.Caption := '<i class="material-icons" style="font-size:40px;">remove</i>';
    btnDvigDec.ChildOrder := 6;
    btnDvigDec.ElementClassName := 'btn btn-success';
    btnDvigDec.ElementFont := efCSS;
    btnDvigDec.HeightPercent := 100.000000000000000000;
    btnDvigDec.WidthPercent := 100.000000000000000000;
    SetEvent(btnDvigDec, Self, 'OnClick', 'btnDvigDecClick');
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 88;
    WebPanel2.Width := 644;
    WebPanel2.Height := 82;
    WebPanel2.Align := alTop;
    WebPanel2.BorderStyle := bsNone;
    WebPanel2.ChildOrder := 14;
    WebPanel2.Color := clWhite;
    WebPanel2.Padding.Left := 5;
    WebPanel2.Padding.Top := 5;
    WebPanel2.Padding.Right := 5;
    WebPanel2.Padding.Bottom := 5;
    WebPanel2.TabOrder := 4;
    WebLabel13.SetParentComponent(WebPanel2);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 5;
    WebLabel13.Top := 5;
    WebLabel13.Width := 455;
    WebLabel13.Height := 72;
    WebLabel13.Margins.Left := 10;
    WebLabel13.Margins.Right := 10;
    WebLabel13.Align := alClient;
    WebLabel13.AutoSize := False;
    WebLabel13.Caption := 'Pranje kontejnerja';
    WebLabel13.Color := clBtnFace;
    WebLabel13.ElementClassName := 'display-3';
    WebLabel13.ElementFont := efCSS;
    WebLabel13.HeightPercent := 100.000000000000000000;
    WebLabel13.WidthPercent := 100.000000000000000000;
    WebLabel14.SetParentComponent(WebPanel2);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 465;
    WebLabel14.Top := 5;
    WebLabel14.Width := 5;
    WebLabel14.Height := 72;
    WebLabel14.Margins.Left := 10;
    WebLabel14.Margins.Right := 10;
    WebLabel14.Align := alRight;
    WebLabel14.AutoSize := False;
    WebLabel14.Color := clBtnFace;
    WebLabel14.ElementClassName := 'display-3';
    WebLabel14.ElementFont := efCSS;
    WebLabel14.HeightPercent := 100.000000000000000000;
    WebLabel14.WidthPercent := 100.000000000000000000;
    WebLabel15.SetParentComponent(WebPanel2);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 460;
    WebLabel15.Top := 5;
    WebLabel15.Width := 5;
    WebLabel15.Height := 72;
    WebLabel15.Margins.Left := 10;
    WebLabel15.Margins.Right := 10;
    WebLabel15.Align := alRight;
    WebLabel15.AutoSize := False;
    WebLabel15.Color := clBtnFace;
    WebLabel15.ElementClassName := 'display-3';
    WebLabel15.ElementFont := efCSS;
    WebLabel15.HeightPercent := 100.000000000000000000;
    WebLabel15.WidthPercent := 100.000000000000000000;
    chxPranje.SetParentComponent(WebPanel2);
    chxPranje.Name := 'chxPranje';
    chxPranje.Left := 470;
    chxPranje.Top := 5;
    chxPranje.Width := 169;
    chxPranje.Height := 72;
    chxPranje.Align := alRight;
    chxPranje.Style := tsRounded;
    WebPanel3.SetParentComponent(Self);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 424;
    WebPanel3.Width := 644;
    WebPanel3.Height := 82;
    WebPanel3.Align := alTop;
    WebPanel3.BorderStyle := bsNone;
    WebPanel3.ChildOrder := 14;
    WebPanel3.Color := clWhite;
    WebPanel3.Padding.Left := 5;
    WebPanel3.Padding.Top := 5;
    WebPanel3.Padding.Right := 5;
    WebPanel3.Padding.Bottom := 5;
    WebPanel3.TabOrder := 5;
    WebPanel3.Visible := False;
    WebLabel10.SetParentComponent(WebPanel3);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 5;
    WebLabel10.Top := 5;
    WebLabel10.Width := 320;
    WebLabel10.Height := 72;
    WebLabel10.Margins.Left := 10;
    WebLabel10.Margins.Right := 10;
    WebLabel10.Align := alClient;
    WebLabel10.AutoSize := False;
    WebLabel10.Caption := 'Ponovni odvoz';
    WebLabel10.Color := clBtnFace;
    WebLabel10.ElementClassName := 'display-3';
    WebLabel10.ElementFont := efCSS;
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.WidthPercent := 100.000000000000000000;
    WebLabel11.SetParentComponent(WebPanel3);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 330;
    WebLabel11.Top := 5;
    WebLabel11.Width := 5;
    WebLabel11.Height := 72;
    WebLabel11.Margins.Left := 10;
    WebLabel11.Margins.Right := 10;
    WebLabel11.Align := alRight;
    WebLabel11.AutoSize := False;
    WebLabel11.Color := clBtnFace;
    WebLabel11.ElementClassName := 'display-3';
    WebLabel11.ElementFont := efCSS;
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.WidthPercent := 100.000000000000000000;
    WebLabel12.SetParentComponent(WebPanel3);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 325;
    WebLabel12.Top := 5;
    WebLabel12.Width := 5;
    WebLabel12.Height := 72;
    WebLabel12.Margins.Left := 10;
    WebLabel12.Margins.Right := 10;
    WebLabel12.Align := alRight;
    WebLabel12.AutoSize := False;
    WebLabel12.Color := clBtnFace;
    WebLabel12.ElementClassName := 'display-3';
    WebLabel12.ElementFont := efCSS;
    WebLabel12.HeightPercent := 100.000000000000000000;
    WebLabel12.WidthPercent := 100.000000000000000000;
    btnPonovitev.SetParentComponent(WebPanel3);
    btnPonovitev.Name := 'btnPonovitev';
    btnPonovitev.Left := 335;
    btnPonovitev.Top := 5;
    btnPonovitev.Width := 304;
    btnPonovitev.Height := 72;
    btnPonovitev.Align := alRight;
    btnPonovitev.Caption := '<i style="font-size:40px;">Normalen potek</i>';
    btnPonovitev.ChildOrder := 6;
    btnPonovitev.ElementFont := efCSS;
    btnPonovitev.HeightPercent := 100.000000000000000000;
    btnPonovitev.WidthPercent := 100.000000000000000000;
    tmrStart.SetParentComponent(Self);
    tmrStart.Name := 'tmrStart';
    tmrStart.Enabled := False;
    tmrStart.Interval := 100;
    SetEvent(tmrStart, Self, 'OnTimer', 'tmrStartTimer');
    tmrStart.Left := 12;
    tmrStart.Top := 16;
    wdsPodrobno.SetParentComponent(Self);
    wdsPodrobno.Name := 'wdsPodrobno';
    wdsPodrobno.AfterOpen := wdsPodrobnoAfterOpen;
    wdsPodrobno.EntitySetName := 'PrevozniNalogPodrobno';
    wdsPodrobno.Connection := modMain.connServer;
    wdsPodrobno.SubpropsDepth := 3;
    wdsPodrobno.Left := 104;
    wdsPodrobno.Top := 16;
    wdsPodrobnoId.SetParentComponent(wdsPodrobno);
    wdsPodrobnoId.Name := 'wdsPodrobnoId';
    wdsPodrobnoId.FieldName := 'Id';
    wdsPodrobnoId.Required := True;
    wdsPodrobnoNalogVoziloTipVozilaId.SetParentComponent(wdsPodrobno);
    wdsPodrobnoNalogVoziloTipVozilaId.Name := 'wdsPodrobnoNalogVoziloTipVozilaId';
    wdsPodrobnoNalogVoziloTipVozilaId.FieldName := 'Nalog.Vozilo.TipVozila.Id';
    wdsPodrobnoPranje.SetParentComponent(wdsPodrobno);
    wdsPodrobnoPranje.Name := 'wdsPodrobnoPranje';
    wdsPodrobnoPranje.FieldName := 'Pranje';
    wdsPodrobnoLocevanjeUr.SetParentComponent(wdsPodrobno);
    wdsPodrobnoLocevanjeUr.Name := 'wdsPodrobnoLocevanjeUr';
    wdsPodrobnoLocevanjeUr.FieldName := 'LocevanjeUr';
    wdsPodrobnoLocevanjeUr.Required := True;
    wdsPodrobnoLocevanjeDelavci.SetParentComponent(wdsPodrobno);
    wdsPodrobnoLocevanjeDelavci.Name := 'wdsPodrobnoLocevanjeDelavci';
    wdsPodrobnoLocevanjeDelavci.FieldName := 'LocevanjeDelavci';
    wdsPodrobnoLocevanjeDelavci.Required := True;
    wdsPodrobnoDvigaloUr.SetParentComponent(wdsPodrobno);
    wdsPodrobnoDvigaloUr.Name := 'wdsPodrobnoDvigaloUr';
    wdsPodrobnoDvigaloUr.FieldName := 'DvigaloUr';
    wdsPodrobnoStojnina.SetParentComponent(wdsPodrobno);
    wdsPodrobnoStojnina.Name := 'wdsPodrobnoStojnina';
    wdsPodrobnoStojnina.FieldName := 'Stojnina';
    wdsPodrobnoStojnina.Required := True;
    wdsPodrobnoTeza.SetParentComponent(wdsPodrobno);
    wdsPodrobnoTeza.Name := 'wdsPodrobnoTeza';
    wdsPodrobnoTeza.FieldName := 'Teza';
    dsPodrobno.SetParentComponent(Self);
    dsPodrobno.Name := 'dsPodrobno';
    dsPodrobno.DataSet := wdsPodrobno;
    dsPodrobno.Left := 104;
    dsPodrobno.Top := 80;
  finally
    lblTitle.AfterLoadDFMValues;
    panFooter.AfterLoadDFMValues;
    btnOk.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    btnPonovo.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    btnUrInc.AfterLoadDFMValues;
    edtSortUr.AfterLoadDFMValues;
    btnUrDec.AfterLoadDFMValues;
    panSortDel.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    btnDelInc.AfterLoadDFMValues;
    edtSortDel.AfterLoadDFMValues;
    btnDelDec.AfterLoadDFMValues;
    panDvigaloUr.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    btnDvigInc.AfterLoadDFMValues;
    edtDvigaloUr.AfterLoadDFMValues;
    btnDvigDec.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    chxPranje.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    btnPonovitev.AfterLoadDFMValues;
    tmrStart.AfterLoadDFMValues;
    wdsPodrobno.AfterLoadDFMValues;
    wdsPodrobnoId.AfterLoadDFMValues;
    wdsPodrobnoNalogVoziloTipVozilaId.AfterLoadDFMValues;
    wdsPodrobnoPranje.AfterLoadDFMValues;
    wdsPodrobnoLocevanjeUr.AfterLoadDFMValues;
    wdsPodrobnoLocevanjeDelavci.AfterLoadDFMValues;
    wdsPodrobnoDvigaloUr.AfterLoadDFMValues;
    wdsPodrobnoStojnina.AfterLoadDFMValues;
    wdsPodrobnoTeza.AfterLoadDFMValues;
    dsPodrobno.AfterLoadDFMValues;
  end;
end;

end.
