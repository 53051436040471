program Terminal;

{$R *.dres}

uses
  Vcl.Forms,
  WEBLib.Forms,
  Auth.Service in 'Auth.Service.pas',
  Client.Settings in 'Client.Settings.pas' {*.html},
  Client.Core in 'Client.Core.pas',
  TerminalForm in '..\Terminal.Common\Templates\TerminalForm.pas',
  Client.Constants in '..\Terminal.Common\Client.Constants.pas',
  Main in 'Main.pas'{*.html},
  Login in '..\Terminal.Common\Frames\Login.pas'{*.html},
  Kilometri in '..\Terminal.Common\Frames\Kilometri.pas'{*.html},
  Gorivo in '..\Terminal.Common\Frames\Gorivo.pas'{*.html},
  Frakcija in '..\Terminal.Common\Frames\Frakcija.pas'{*.html},
  Datum in '..\Terminal.Common\Frames\Datum.pas'{*.html},
  Vozilo in '..\Terminal.Common\Frames\Vozilo.pas'{*.html},
  Lokacija in 'Norba\Lokacija.pas'{*.html},
  VoziloMenjava in 'Norba\VoziloMenjava.pas'{*.html},
  Settings in 'Norba\Settings.pas'{*.html},
  Zemljevid in 'Norba\Zemljevid.pas'{*.html},
  Zgodovina in 'Norba\Zgodovina.pas'{*.html},
  Odvoz in 'Norba\Odvoz.pas'{*.html},
  OdvozInfo in 'Norba\OdvozInfo.pas'{*.html},
  Potrditev in 'Norba\Potrditev.pas'{*.html},
  Nalog in 'Nakladac\Nalog.pas'{*.html},
  Nalogi in 'Nakladac\Nalogi.pas'{*.html},
  KontejnerPredaja in 'Nakladac\KontejnerPredaja.pas'{*.html},
  Teza in 'Nakladac\Teza.pas'{*.html},
  Storitve in 'Nakladac\Storitve.pas'{*.html},
  ZemljevidCnt in 'Nakladac\ZemljevidCnt.pas'{*.html},
  Obvestilo in '..\Terminal.Common\Frames\Obvestilo.pas' {*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateFormDirect(TmodMain, modMain);
  Application.CreateForm(TfrmMain, frmMain);
  Application.CreateForm(TfrmLogin, frmLogin);
  Application.CreateForm(TfrmLogin, frmLogin);
  Application.CreateForm(TfrmKilometri, frmKilometri);
  Application.CreateForm(TfrmGorivo, frmGorivo);
  Application.Run;
end.
